import { createApp, defineAsyncComponent, h } from 'vue'
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import router from './router'
import App from './App.vue'
//import './style.css'
import './css/main.css'
import './css/choices.css'
import './css/custom.css'
import './css/light-theme.css'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
/*
const Registration = () => import('./Registration.vue')

const routes = [
    { path: '/auth', component: Auth, name:'auth' },
    { path: '/test', component: Test },
    { path: '/personal/', component: Registration, name:'registration' },
    { path: '/catalog/', component: Catalog, name:'catalog' },
    { path: '/', component: Main, name:'main', meta: {layout: 'main'}, }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {

  // debugger;
    //if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
    //else
    next()
})
*/
/*let device_uuid = (new Date()).getTime();

if (localStorage.getItem('user-token'))
    device_uuid = localStorage.getItem('user-token');

localStorage.setItem('user-token', device_uuid)
*/
    //axios.defaults.params = {Authorization: token}
axios.defaults.headers.common['Authorization'] = 'Basic cHJvZml0OmtpdA==';
axios.defaults.baseURL = 'https://magicline.alm.su/';

const app2 = createApp(App)
app2.use(router)
app2.use(store)
app2.use(VueAxios, axios)
app2.mount('#app')




