<template>
  <SalesLiderGroup></SalesLiderGroup>
</template>

<script>
import SalesLiderGroup from "@/views/SalesLiderGroup"

export default {
  data: () => ({
    items: {},
  }),
  beforeRouteLeave(to, from) {
    //if (to.name != 'login')
    //  this.$store.dispatch('unLoader');
  },
  created() {

  },
  computed: {

  },
  async mounted() {

  },
  components: {SalesLiderGroup},
  methods: {

  },
}
</script>