<template>
  <template v-if="pdf">
    <h1 class="title">Сформировать PDF</h1>
    <div class="section">
      <div class="section__title">Выберите фотографии пациента</div>
      <div class="photos">
        <div @click="selectPhoto('doctorPhotos', key)" :class="photo.select?'select_photo':''" class="photos__item" v-for="(photo, key) in doctorPhotos">
          <img v-if="photo.link" :src="photo.link" alt="">
          {{photo.link}}
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section__title">Выберите изображения</div>
      <div class="photos">
        <div @click="selectPhoto('photos', key)" :class="photo.select?'select_photo':''" class="photos__item" v-for="(photo, key) in photos">
          <img v-if="photo.link" :src="photo.link" alt="">
          {{photo.link}}
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section__title">План лечения</div>
      <div class="plan" v-html="plan"></div>
    </div>
    <div class="row flex_start">
      <div class="section__btn btn btn--big btn--primary" @click="loadPdf"><span>Сформировать PDF</span></div>
      <div class="section__btn btn btn--big" @click="pdf=false"><span>Отменить</span></div>
    </div>
  </template>
  <template v-else-if="name">
    <h1 class="title">{{ name }}</h1>
    <div class="tabs">
        <div class="tabs-item" :class="tabs.treatmentOpen ? 'is-active' : ''" data-tab="treatment"
             @click="openTab('treatmentOpen')">Лечение
        </div>
        <div class="tabs-item" :class="tabs.materialsOpen ? 'is-active' : ''" data-tab="doc-materials"
             @click="openTab('materialsOpen')">Документы врача
        </div>
        <div class="tabs-item" :class="tabs.setupOpen ? 'is-active' : ''" data-tab="3d-setup"
             @click="openTab('setupOpen')">3D Сетап
        </div>
        <div class="tabs-item" :class="tabs.progressOpen ? 'is-active' : ''" data-tab="progress"
             @click="openTab('progressOpen')">Прогресс
        </div>
    </div>
    <div class="tabs-content is-open" data-tab="treatment" v-if="tabs.treatmentOpen">
      <div class="section" v-if="role == 'admin'">
        <div class="row flex_start">
        <div class="section__btn btn btn--big btn--icon" @click="treatment_edit = !treatment_edit"><span>Редактировать</span>
          <svg width="30" height="30">
            <use xlink:href="@/images/sprite.svg#edit"></use>
          </svg>
        </div>
        <div class="section__btn btn btn--big btn--icon" @click="createPdf"><span>Сформировать PDF</span>
        </div>
        </div>
      </div>
      <div class="section">
            <div class="section__title">Каппы</div>
            <div class="mouthguards" :class="treatment_edit?'mouthguards_edit':''">
                <div class="mouthguards-line">
                    <div class="mouthguards__symbol">
                        <svg width="16" height="16">
                            <use xlink:href="@/images/sprite.svg#plus"></use>
                        </svg>
                    </div>
                  <div v-if="treatment_edit" @click="capPlusAdd" class="mouthguards__item" ><svg width="14" height="14">
                    <use xlink:href="@/images/sprite.svg#plus-green"></use>
                  </svg></div>
                    <div class="mouthguards-list">
                      <template v-for="(status, id) in caps.plus">
                        <div class="mouthguards__item" @click="capPlusRemove(id)" :class="'is-'+status" v-if="id!=0">{{ id }}</div>
                      </template>

                    </div>
                </div>
                <div class="mouthguards-line">
                    <div class="mouthguards__symbol">
                        <svg width="16" height="16">
                            <use xlink:href="@/images/sprite.svg#minus"></use>
                        </svg>
                    </div>
                  <div v-if="treatment_edit" @click="capMinusAdd" class="mouthguards__item" ><svg width="14" height="14">
                    <use xlink:href="@/images/sprite.svg#plus-green"></use>
                  </svg></div>
                    <div class="mouthguards-list">

                      <template v-for="(status, id) in caps.minus">
                        <div class="mouthguards__item" @click="capMinusRemove(id)" :class="'is-'+status" v-if="id!=0">{{ id }}</div>
                      </template>

                    </div>
                </div>
            </div>
            <div v-if="role=='doctor'" @click="showAddPrint = !showAddPrint" class="section__btn btn btn--big btn--primary">Заявка на изготовление</div>
        </div>
        <div class="section">
            <div class="section__title">План лечения</div>

            <div v-if="role=='doctor'" class="section__btn btn btn--big btn--icon" @click="showChangesModal = !showChangesModal"><span>Предложить изменения</span>
                <svg width="30" height="30">
                    <use xlink:href="@/images/sprite.svg#plus-big"></use>
                </svg>
            </div>
            <div v-if="!treatment_edit" class="plan" v-html="plan"></div>
          <div v-if="treatment_edit" class="form-group">
            <textarea  ref="planTextarea" class="form-control heightAuto" v-model="plan"></textarea>
          </div>
        </div>
      <div class="section">
        <div class="section__title">Фотографии / Видео <div @click="copyLink('photos')" class="copy_link" :class="copyClass.photos?'copy_link_success':''">Скопировать ссылку
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8.5V5.7C8 4.5799 8 4.01984 8.21799 3.59202C8.40973 3.21569 8.71569 2.90973 9.09202 2.71799C9.51984 2.5 10.0799 2.5 11.2 2.5H18.8C19.9201 2.5 20.4802 2.5 20.908 2.71799C21.2843 2.90973 21.5903 3.21569 21.782 3.59202C22 4.01984 22 4.5799 22 5.7V13.3C22 14.4201 22 14.9802 21.782 15.408C21.5903 15.7843 21.2843 16.0903 20.908 16.282C20.4802 16.5 19.9201 16.5 18.8 16.5H16M5.2 22.5H12.8C13.9201 22.5 14.4802 22.5 14.908 22.282C15.2843 22.0903 15.5903 21.7843 15.782 21.408C16 20.9802 16 20.4201 16 19.3V11.7C16 10.5799 16 10.0198 15.782 9.59202C15.5903 9.21569 15.2843 8.90973 14.908 8.71799C14.4802 8.5 13.9201 8.5 12.8 8.5H5.2C4.0799 8.5 3.51984 8.5 3.09202 8.71799C2.71569 8.90973 2.40973 9.21569 2.21799 9.59202C2 10.0198 2 10.5799 2 11.7V19.3C2 20.4201 2 20.9802 2.21799 21.408C2.40973 21.7843 2.71569 22.0903 3.09202 22.282C3.51984 22.5 4.07989 22.5 5.2 22.5Z" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div></div>
        <div class="docs" v-if="photos.length > 0">
          <template v-for="(file, index) in photos">
            <a :href="file.link" target="_blank" class="doc" :class="[treatment_edit?'doc--edit':'']" >
              <span  class="doc__name">{{ file.filename }}</span>
              <div class="doc__size">{{ file.format_size }}</div>
              <svg v-if="file.save != 1 && file.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
                <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(file.progress/100)" stroke="#21D28B" ></circle>
              </svg>
              <div v-if="treatment_edit" class="doc__delete remove" @click.prevent="deleteFile('photos', index)"><svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#remove"></use>
              </svg></div>
            </a>
            <div v-if="file.error" class="error_file">{{file.error}}</div>
          </template>
        </div>
        <template v-if="treatment_edit">
          <div class="section__information">Форматы файлов: {{extention.photos.join(', ')}}. Максимальный размер файла — {{formatBytes(maxSize.photos)}}. </div>
          <label class="section__btn btn btn--icon btn--big" >
            <input type="file" name="" multiple  @input="addFiles('photos')" ref="photos">
            <span v-if="photos.length == 0">Выбрать файл</span>
            <span v-else>Выбрать другой файл</span>
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#plus-big"></use>
            </svg>
          </label>
        </template>
      </div>

        <div class="section">
            <div class="section__title">Отчет<div @click="copyLink('report')" class="copy_link" :class="copyClass.report?'copy_link_success':''">Скопировать ссылку
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8.5V5.7C8 4.5799 8 4.01984 8.21799 3.59202C8.40973 3.21569 8.71569 2.90973 9.09202 2.71799C9.51984 2.5 10.0799 2.5 11.2 2.5H18.8C19.9201 2.5 20.4802 2.5 20.908 2.71799C21.2843 2.90973 21.5903 3.21569 21.782 3.59202C22 4.01984 22 4.5799 22 5.7V13.3C22 14.4201 22 14.9802 21.782 15.408C21.5903 15.7843 21.2843 16.0903 20.908 16.282C20.4802 16.5 19.9201 16.5 18.8 16.5H16M5.2 22.5H12.8C13.9201 22.5 14.4802 22.5 14.908 22.282C15.2843 22.0903 15.5903 21.7843 15.782 21.408C16 20.9802 16 20.4201 16 19.3V11.7C16 10.5799 16 10.0198 15.782 9.59202C15.5903 9.21569 15.2843 8.90973 14.908 8.71799C14.4802 8.5 13.9201 8.5 12.8 8.5H5.2C4.0799 8.5 3.51984 8.5 3.09202 8.71799C2.71569 8.90973 2.40973 9.21569 2.21799 9.59202C2 10.0198 2 10.5799 2 11.7V19.3C2 20.4201 2 20.9802 2.21799 21.408C2.40973 21.7843 2.71569 22.0903 3.09202 22.282C3.51984 22.5 4.07989 22.5 5.2 22.5Z" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div></div>
            <div class="docs" v-if="report.length > 0">
              <template v-for="(file, index) in report">
              <a :href="file.link" target="_blank" class="doc" :class="[treatment_edit?'doc--edit':'']" >
                <span  class="doc__name">{{ file.filename }}</span>
                <div class="doc__size">{{ file.format_size }}</div>
                <svg v-if="file.save != 1 && file.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
                  <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(file.progress/100)" stroke="#21D28B" ></circle>
                </svg>
                <div v-if="treatment_edit" class="doc__delete remove" @click.prevent="deleteFile('report', index)"><svg width="30" height="30">
                  <use xlink:href="@/images/sprite.svg#remove"></use>
                </svg></div>
              </a>
              <div v-if="file.error" class="error_file">{{file.error}}</div>
              </template>
            </div>
          <template v-if="treatment_edit">
            <div class="section__information">Форматы файлов: {{extention.report.join(', ')}}. Максимальный размер файла — {{formatBytes(maxSize.report)}}. </div>
            <label class="section__btn btn btn--icon btn--big" >
              <input type="file" name=""  @input="addFiles('report')" ref="report">
              <span v-if="report.length == 0">Выбрать файл</span>
              <span v-else>Выбрать другой файл</span>
              <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
              </svg>
            </label>
          </template>
        </div>
      <div class="section" v-if="treatment_edit">
        <div class="row flex_start">
          <div class="section__btn btn btn--big btn--primary" @click="saveTreatment"><span>Сохранить изменения</span></div>
          <div class="section__btn btn btn--big" @click="cancel"><span>Отменить</span></div>
        </div>
      </div>
    </div>
    <div class="tabs-content is-open" data-tab="doc-materials" v-if="tabs.materialsOpen">
      <div class="section" v-if="role == 'doctor'">
          <div v-if="!doc_edit" class="section__btn btn btn--big btn--icon" @click="doc_edit = !doc_edit"><span>Редактировать</span>
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#edit"></use>
            </svg>
          </div>
      </div>
        <div class="section">
            <div class="section__title">КЛКТ челюсти<div @click="copyLink('klkt')" class="copy_link" :class="copyClass.klkt?'copy_link_success':''">Скопировать ссылку
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8.5V5.7C8 4.5799 8 4.01984 8.21799 3.59202C8.40973 3.21569 8.71569 2.90973 9.09202 2.71799C9.51984 2.5 10.0799 2.5 11.2 2.5H18.8C19.9201 2.5 20.4802 2.5 20.908 2.71799C21.2843 2.90973 21.5903 3.21569 21.782 3.59202C22 4.01984 22 4.5799 22 5.7V13.3C22 14.4201 22 14.9802 21.782 15.408C21.5903 15.7843 21.2843 16.0903 20.908 16.282C20.4802 16.5 19.9201 16.5 18.8 16.5H16M5.2 22.5H12.8C13.9201 22.5 14.4802 22.5 14.908 22.282C15.2843 22.0903 15.5903 21.7843 15.782 21.408C16 20.9802 16 20.4201 16 19.3V11.7C16 10.5799 16 10.0198 15.782 9.59202C15.5903 9.21569 15.2843 8.90973 14.908 8.71799C14.4802 8.5 13.9201 8.5 12.8 8.5H5.2C4.0799 8.5 3.51984 8.5 3.09202 8.71799C2.71569 8.90973 2.40973 9.21569 2.21799 9.59202C2 10.0198 2 10.5799 2 11.7V19.3C2 20.4201 2 20.9802 2.21799 21.408C2.40973 21.7843 2.71569 22.0903 3.09202 22.282C3.51984 22.5 4.07989 22.5 5.2 22.5Z" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div></div>
            <div class="docs">
                <a :href="file.link" target="_blank" class="doc" :class="[doc_edit?'doc--edit':'']" v-for="(file, index) in klkt">
                  <svg v-if="file.save != 1 && file.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
                    <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(file.progress/100)" stroke="#21D28B" ></circle>
                  </svg>
                    <span class="doc__name">{{ file.filename }}</span>
                    <div class="doc__size">{{ file.format_size }}</div>
                  <div v-if="doc_edit" class="doc__delete remove" @click.prevent="deleteFile('klkt', index)"><svg width="30" height="30">
                    <use xlink:href="@/images/sprite.svg#remove"></use>
                  </svg></div>
                </a>
            </div>
          <template v-if="doc_edit">
            <div class="section__information">Форматы файлов: {{extention.klkt.join(', ')}}. Максимальный размер файла — {{formatBytes(maxSize.klkt)}}. </div>
            <label class="section__btn btn btn--icon btn--big">
              <input type="file"  multiple  @input="addFiles('klkt')" ref="klkt"><span>Выбрать файлы</span>
              <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
              </svg>
            </label>
          </template>
        </div>
        <div class="section">
            <div class="section__title">Сканы (.stl)<div @click="copyLink('scan')" class="copy_link" :class="copyClass.scan?'copy_link_success':''">Скопировать ссылку
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8.5V5.7C8 4.5799 8 4.01984 8.21799 3.59202C8.40973 3.21569 8.71569 2.90973 9.09202 2.71799C9.51984 2.5 10.0799 2.5 11.2 2.5H18.8C19.9201 2.5 20.4802 2.5 20.908 2.71799C21.2843 2.90973 21.5903 3.21569 21.782 3.59202C22 4.01984 22 4.5799 22 5.7V13.3C22 14.4201 22 14.9802 21.782 15.408C21.5903 15.7843 21.2843 16.0903 20.908 16.282C20.4802 16.5 19.9201 16.5 18.8 16.5H16M5.2 22.5H12.8C13.9201 22.5 14.4802 22.5 14.908 22.282C15.2843 22.0903 15.5903 21.7843 15.782 21.408C16 20.9802 16 20.4201 16 19.3V11.7C16 10.5799 16 10.0198 15.782 9.59202C15.5903 9.21569 15.2843 8.90973 14.908 8.71799C14.4802 8.5 13.9201 8.5 12.8 8.5H5.2C4.0799 8.5 3.51984 8.5 3.09202 8.71799C2.71569 8.90973 2.40973 9.21569 2.21799 9.59202C2 10.0198 2 10.5799 2 11.7V19.3C2 20.4201 2 20.9802 2.21799 21.408C2.40973 21.7843 2.71569 22.0903 3.09202 22.282C3.51984 22.5 4.07989 22.5 5.2 22.5Z" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div></div>
            <div class="docs">
                <a :href="file.link"  target="_blank" class="doc" :class="[doc_edit?'doc--edit':'']" v-for="(file, index) in scan" >
                  <svg v-if="file.save != 1 && file.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
                    <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(file.progress/100)" stroke="#21D28B" ></circle>
                  </svg>
                    <span class="doc__name">{{ file.filename }}</span>
                    <div class="doc__size">{{ file.format_size }}</div>
                  <div v-if="doc_edit" class="doc__delete remove" @click.prevent="deleteFile('scan', index)"><svg width="30" height="30">
                    <use xlink:href="@/images/sprite.svg#remove"></use>
                  </svg></div>
                </a>
            </div>
          <template v-if="doc_edit">
            <div class="section__information">Форматы файлов: {{extention.scan.join(', ')}}. Максимальный размер файла — {{formatBytes(maxSize.scan)}}.</div>
            <label class="section__btn btn btn--icon btn--big">
              <input type="file"  multiple  @input="addFiles('scan')" ref="scan"><span>Выбрать файлы</span>
              <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
              </svg>
            </label>
          </template>
        </div>
        <div class="section">
            <div class="section__title">Фотографии пациента<div @click="copyLink('doctorPhotos')" class="copy_link" :class="copyClass.doctorPhotos?'copy_link_success':''">Скопировать ссылку
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8.5V5.7C8 4.5799 8 4.01984 8.21799 3.59202C8.40973 3.21569 8.71569 2.90973 9.09202 2.71799C9.51984 2.5 10.0799 2.5 11.2 2.5H18.8C19.9201 2.5 20.4802 2.5 20.908 2.71799C21.2843 2.90973 21.5903 3.21569 21.782 3.59202C22 4.01984 22 4.5799 22 5.7V13.3C22 14.4201 22 14.9802 21.782 15.408C21.5903 15.7843 21.2843 16.0903 20.908 16.282C20.4802 16.5 19.9201 16.5 18.8 16.5H16M5.2 22.5H12.8C13.9201 22.5 14.4802 22.5 14.908 22.282C15.2843 22.0903 15.5903 21.7843 15.782 21.408C16 20.9802 16 20.4201 16 19.3V11.7C16 10.5799 16 10.0198 15.782 9.59202C15.5903 9.21569 15.2843 8.90973 14.908 8.71799C14.4802 8.5 13.9201 8.5 12.8 8.5H5.2C4.0799 8.5 3.51984 8.5 3.09202 8.71799C2.71569 8.90973 2.40973 9.21569 2.21799 9.59202C2 10.0198 2 10.5799 2 11.7V19.3C2 20.4201 2 20.9802 2.21799 21.408C2.40973 21.7843 2.71569 22.0903 3.09202 22.282C3.51984 22.5 4.07989 22.5 5.2 22.5Z" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div></div>
          <span style="display:none;">{{random}}</span>
            <div class="photos">
                <div class="photos__item" v-for="(photo, key) in doctorPhotos">
                  <svg v-if="photo.save != 1 && photo.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
                    <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(photo.progress/100)" stroke="#21D28B" ></circle>
                  </svg>
                    <img v-if="photo.link" :src="photo.link" alt="">
                    <div class="photos__item-action">
                        <div class="photos__item-show show js-open-modal" data-modal="gallery" @click="modalPhoto(doctorPhotos, key)">
                            <svg width="30" height="30">
                                <use xlink:href="@/images/sprite.svg#eye"></use>
                            </svg>
                        </div>
                        <div v-if="doc_edit" class="photos__item-remove remove"  @click="deleteFile('doctorPhotos', key)">
                            <svg width="30" height="30">
                                <use xlink:href="@/images/sprite.svg#remove"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
          <template v-if="doc_edit">
            <div class="section__information">Форматы файлов: {{extention.doctorPhotos.join(', ')}}. Максимальный размер файла — {{formatBytes(maxSize.doctorPhotos)}}. </div>
            <label class="section__btn btn btn--icon btn--big" >
              <input type="file"  multiple  @input="addFiles('doctorPhotos')" ref="doctorPhotos"><span>Выбрать фото</span>
              <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
              </svg>
            </label>
          </template>
        </div>
        <div class="section">
            <div class="section__title">Комментарий от врача</div>
            <div v-if="!doc_edit" class="comment">{{ comment }}</div>
          <div v-if="doc_edit" class="comment form-group">
            <textarea class="form-control" v-model="comment"></textarea>
          </div>
        </div>
        <div class="section" v-if="doc_edit">
            <div class="section__btn btn btn--big btn--primary" @click="saveDocs"><span>Сохранить изменения</span>
            </div>
        </div>
    </div>
    <div class="tabs-content is-open" data-tab="3d-setup" v-if="tabs.setupOpen">
      <div class="section" v-if="role == 'admin'">
        <div class="section__btn btn btn--big btn--icon" @click="setup_edit = !setup_edit"><span>Редактировать</span>
          <svg width="30" height="30">
            <use xlink:href="@/images/sprite.svg#edit"></use>
          </svg>
        </div>
      </div>
        <div class="section">
          <div class="section__title">3D Сетап<div @click="copyLink('threeDSetup')" class="copy_link" :class="copyClass.threeDSetup?'copy_link_success':''">Скопировать ссылку
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 8.5V5.7C8 4.5799 8 4.01984 8.21799 3.59202C8.40973 3.21569 8.71569 2.90973 9.09202 2.71799C9.51984 2.5 10.0799 2.5 11.2 2.5H18.8C19.9201 2.5 20.4802 2.5 20.908 2.71799C21.2843 2.90973 21.5903 3.21569 21.782 3.59202C22 4.01984 22 4.5799 22 5.7V13.3C22 14.4201 22 14.9802 21.782 15.408C21.5903 15.7843 21.2843 16.0903 20.908 16.282C20.4802 16.5 19.9201 16.5 18.8 16.5H16M5.2 22.5H12.8C13.9201 22.5 14.4802 22.5 14.908 22.282C15.2843 22.0903 15.5903 21.7843 15.782 21.408C16 20.9802 16 20.4201 16 19.3V11.7C16 10.5799 16 10.0198 15.782 9.59202C15.5903 9.21569 15.2843 8.90973 14.908 8.71799C14.4802 8.5 13.9201 8.5 12.8 8.5H5.2C4.0799 8.5 3.51984 8.5 3.09202 8.71799C2.71569 8.90973 2.40973 9.21569 2.21799 9.59202C2 10.0198 2 10.5799 2 11.7V19.3C2 20.4201 2 20.9802 2.21799 21.408C2.40973 21.7843 2.71569 22.0903 3.09202 22.282C3.51984 22.5 4.07989 22.5 5.2 22.5Z" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div></div>
            <div v-if="false && threeDSetup" class="setup">
                <div class="setup__img"><img :src="threeDSetup" alt=""></div>
            </div>
          <div class="docs" v-if="threeDSetup.length > 0">
            <template v-for="(file, index) in threeDSetup">
            <a :href="file.link" target="_blank" class="doc" :class="[doc_edit?'doc--edit':'']" >
              <svg v-if="file.save != 1 && file.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
                <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(file.progress/100)" stroke="#21D28B" ></circle>
              </svg>
              <span  class="doc__name">{{ file.filename }}</span>
              <div class="doc__size">{{ file.format_size }}</div>
              <div v-if="doc_edit" class="doc__delete remove" @click.prevent="deleteFile('threeDSetup', index)"><svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#remove"></use>
              </svg></div>
            </a>
            <div v-if="file.error" class="error_file">{{file.error}}</div>
            </template>
          </div>

        </div>
      <div class="section" v-if="setup_edit">
        <label class="section__btn btn btn--icon btn--big">
          <input type="file" name="" @input="addFiles('threeDSetup')" ref="threeDSetup">
          <span v-if="threeDSetup.length == 0">Выбрать файл</span>
          <span v-else>Выбрать другой файл</span>
          <svg width="30" height="30">
            <use xlink:href="@/images/sprite.svg#plus-big"></use>
          </svg>
        </label>
        <div class="row flex_start">
          <div class="section__btn btn btn--big btn--primary" @click="setupSave"><span>Сохранить изменения</span></div>
          <div class="section__btn btn btn--big" @click="setup_edit = !setup_edit"><span>Отменить</span></div>
        </div>
      </div>
    </div>
    <div class="tabs-content is-open" data-tab="progress" v-if="tabs.progressOpen">
      <div class="section" v-if="role == 'doctor'">
        <div class="section__btn btn btn--big btn--icon" @click="showAddPhoto = !showAddPhoto"><span>Добавить фото</span>
          <svg width="30" height="30">
            <use xlink:href="@/images/sprite.svg#plus-big"></use>
          </svg>
        </div>
      </div>
        <div class="section">
            <div class="section__title">Фотографии прогресса<div @click="copyLink('addProgressPhoto')" class="copy_link" :class="copyClass.addProgressPhoto?'copy_link_success':''">Скопировать ссылку
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8.5V5.7C8 4.5799 8 4.01984 8.21799 3.59202C8.40973 3.21569 8.71569 2.90973 9.09202 2.71799C9.51984 2.5 10.0799 2.5 11.2 2.5H18.8C19.9201 2.5 20.4802 2.5 20.908 2.71799C21.2843 2.90973 21.5903 3.21569 21.782 3.59202C22 4.01984 22 4.5799 22 5.7V13.3C22 14.4201 22 14.9802 21.782 15.408C21.5903 15.7843 21.2843 16.0903 20.908 16.282C20.4802 16.5 19.9201 16.5 18.8 16.5H16M5.2 22.5H12.8C13.9201 22.5 14.4802 22.5 14.908 22.282C15.2843 22.0903 15.5903 21.7843 15.782 21.408C16 20.9802 16 20.4201 16 19.3V11.7C16 10.5799 16 10.0198 15.782 9.59202C15.5903 9.21569 15.2843 8.90973 14.908 8.71799C14.4802 8.5 13.9201 8.5 12.8 8.5H5.2C4.0799 8.5 3.51984 8.5 3.09202 8.71799C2.71569 8.90973 2.40973 9.21569 2.21799 9.59202C2 10.0198 2 10.5799 2 11.7V19.3C2 20.4201 2 20.9802 2.21799 21.408C2.40973 21.7843 2.71569 22.0903 3.09202 22.282C3.51984 22.5 4.07989 22.5 5.2 22.5Z" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div></div>
            <div class="progress">
                <div class="progress-item" v-for="(photo, key) in progressPhotos">
                    <div class="progress-item__img">
                        <img :src="photo.link" alt="">
                        <div class="progress-item__action">
                            <div class="progress-item__show show js-open-modal" data-modal="gallery" @click="modalPhoto(progressPhotos, key)">
                                <svg width="30" height="30">
                                    <use xlink:href="@/images/sprite.svg#eye"></use>
                                </svg>
                            </div>
                            <div v-if="role=='doctor'" @click="deleteProgressPhoto(photo.id, key)" class="progress-item__remove remove">
                                <svg width="30" height="30">
                                    <use xlink:href="@/images/sprite.svg#remove"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="progress-item__date">{{ photo.date }}</div>
                    <div class="progress-item__desc">{{ photo.comment }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" :class="showChangesModal ? 'is-open' : ''" data-modal="add-docs">
        <div class="modal-overlay" @click="showChangesModal = !showChangesModal"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="showChangesModal = !showChangesModal">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Предложить изменения</div>
                <div class="modal__desc">Напишите, что нужно изменить в плане лечения</div>
                <form class="modal-form" @submit.prevent="send">
                    <div class="form-group">
                        <input class="form-control" type="text" @input="isFilled" v-model="changesComment">
                        <label class="form-label">Комментарий </label>
                    </div>
                    <div class="modal-docs" v-if="changesFiles">
                        <div class="docs">
                          <template v-for="(file, index) in changesFiles">
                            <div class="doc doc--edit">
                              <svg v-if="file.save != 1 && file.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
                                <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(file.progress/100)" stroke="#21D28B" ></circle>
                              </svg>
                                <div class="doc__name">{{ file.filename }}</div>
                                <div class="doc__size" v-html="file.format_size"></div>
                                <div class="doc__delete" @click="deleteFile('changesFiles', index)"><svg width="30" height="30">
                                  <use xlink:href="@/images/sprite.svg#remove"></use>
                                </svg></div>
                            </div>
                            <div v-if="file.error" class="error_file">{{file.error}}</div>
                          </template>
                        </div>
                    </div>
                    <div class="modal-action modal-action--column">
                        <label class="btn btn--big btn--icon">
                            <input class="hidden-input" type="file" multiple @input="addFiles('changesFiles')" ref="changesFiles">
                            <span>Выбрать файлы</span>
                            <svg width="30" height="30">
                                <use xlink:href="@/images/sprite.svg#plus-big"></use>
                            </svg>
                        </label>
                        <button class="btn btn--big btn--primary">Предложить</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal" :class="showSlide ? 'is-open' : ''" data-modal="gallery" v-if="modalPhotos.length">
        <div class="modal-overlay" @click="showSlide = !showSlide"> </div>
        <div class="modal-wrapper">
            <div class="modal-content modal-content--xl">
                <div class="modal-close" @click="showSlide = !showSlide">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">{{ modalPhotos[currentModalPhoto].date }}</div>
                <div class="modal-gallery">
                    <div class="modal-gallery__slider">
                        <div class="swiper-wrapper">
                            <swiper
                                :initial-slide="currentModalPhoto"
                                :modules="modules"
                                :navigation="{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                }"
                                :loop="false"
                                :autoplay="false"
                                :space-between="0"
                                :slides-per-view="1"
                                @swiper="setSwiperObj"
                                @slideChange="onSlideChange"
                            >
                                <swiper-slide v-for="photo in modalPhotos">
                                    <div class="modal-gallery__item swiper-slide">
                                        <div class="modal-gallery__photo"><img :src="photo.link" alt=""></div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                    <div class="modal-gallery__prev slider-prev swiper-button-prev" ref="prev">
                        <svg width="7" height="14">
                            <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                        </svg>
                    </div>
                    <div class="modal-gallery__next slider-next swiper-button-next" ref="next">
                        <svg width="7" height="14">
                            <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                        </svg>
                    </div>
                </div>
                <div class="modal-gallery__text">{{ modalPhotos[currentModalPhoto].comment }}</div>
            </div>
        </div>
    </div>

    <div class="modal" :class="showAddPhoto ? 'is-open' : ''" data-modal="add-photo">
      <div class="modal-overlay"> </div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close" @click="showAddPhoto=!showAddPhoto">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div class="modal__title">Добавить фото</div>
          <form class="modal-form" @submit.prevent="addProgress">
            <div class="modal-docs" v-if="addProgressPhoto">
              <div class="docs">
                <template v-for="(file, index) in addProgressPhoto">
                  <div class="doc doc--edit">
                    <svg v-if="file.save != 1 && file.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
                      <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(file.progress/100)" stroke="#21D28B" ></circle>
                    </svg>
                    <div class="doc__name">{{ file.filename }}</div>
                    <div class="doc__size" v-html="file.format_size"></div>
                    <div class="doc__delete" @click="deleteFile('addProgressPhoto', index)"><svg width="30" height="30">
                      <use xlink:href="@/images/sprite.svg#remove"></use>
                    </svg></div>
                  </div>
                  <div v-if="file.error" class="error_file">{{file.error}}</div>
                </template>
              </div>
            </div>
            <div class="form-group">
              <div class="modal-action modal-action--column">
                <label class="section__btn btn btn--icon btn--big margin0" >
                  <input type="file" @input="addFiles('addProgressPhoto')" ref="addProgressPhoto"><span>Выбрать фото</span>
                  <svg width="30" height="30">
                    <use xlink:href="@/images/sprite.svg#plus-big"></use>
                  </svg>
                </label>
              </div>
            </div>

            <div class="form-group">
              <input v-model="addProgressDate" class="form-control focus" type="date">
              <label class="form-label">Дата <span>*</span></label>
            </div>
            <div class="form-group">
              <input v-model="addProgressComment" class="form-control" @input="isFilled" type="text">
              <label class="form-label">Комментарий </label>
            </div>
            <div class="modal-action modal-action--column">
              <button type="submit" class="btn btn--big btn--primary" :disabled="!addProgressDate && !addProgressPhoto">Добавить фото</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal" data-modal="print-changed">
      <div class="modal-overlay"> </div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div class="modal__title">Запись успешно изменена!</div>
          <div class="modal-action">
            <div class="btn btn--primary js-modal-close">Ок</div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="showAddPrint ? 'is-open' : ''" data-modal="print-edit">
      <div class="modal-overlay"> </div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close" @click="showAddPrint=false">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div class="modal__title">Заявка на изготовление</div>
          <div class="modal__desc">Отметьте нужные каппы</div>
          <div class="mouthguards">
            <div v-if="Object.keys(caps.plus).length > 0" class="mouthguards-line">
              <div class="mouthguards__symbol">
                <svg width="16" height="16">
                  <use xlink:href="@/images/sprite.svg#plus"></use>
                </svg>
              </div>
              <div class="mouthguards-list">
                <div class="mouthguards__item" :class="getCapRequestStatus(id, 'plus',status)" v-for="(status, id) in caps.plus" @click="addToRequest(id, 'plus', status)">{{ id }}</div>
              </div>
            </div>
            <div v-if="Object.keys(caps.minus).length > 0" class="mouthguards-line">
              <div class="mouthguards__symbol">
                <svg width="16" height="16">
                  <use xlink:href="@/images/sprite.svg#minus"></use>
                </svg>
              </div>
              <div class="mouthguards-list">
                <div class="mouthguards__item" :class="getCapRequestStatus(id, 'minus',status)" v-for="(status, id) in caps.minus" @click="addToRequest(id, 'minus', status)">{{ id }}</div>
              </div>
            </div>
          </div>
          <form class="modal-form modal-form--comments" @submit.prevent="sendPrint">
            <br>
            <div class="form-group">
              <input v-model="printComment" class="form-control" type="text" placeholder="Комментарий">
            </div>
            <div class="modal-action">
              <button type="submit" class="btn btn--primary js-modal-close">Запросить</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal" :class="isSuccess?'is-open':''" data-modal="print-changed">
      <div class="modal-overlay"> </div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close" @click="close">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div class="modal__title">Заявка успешно создана!</div>
          <div class="modal__desc">Скоро мы ее обработаем!</div>
          <div class="modal-action">
            <div class="btn btn--primary js-modal-close" @click="close">Ок</div>
          </div>
        </div>
      </div>
    </div>

  </template>
  <template v-else-if="load">
    загрузка...
  </template>
  <template v-else>
  <Notfound></Notfound>
  </template>
  <span style="display: none;">{{random}}</span>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import {Navigation} from 'swiper';
import axios from "axios";
import Notfound from '@/views/Notfound';

export default {
    data: () => ({
        load: true,
        tabs: {
            treatmentOpen: true,
            materialsOpen: false,
            setupOpen: false,
            progressOpen: false,
        },
        doc_edit: false,
        setup_edit: false,
        treatment_edit: false,
        changesComment: '',
        changesFiles: [],
        showChangesModal: false,
        modalPhotoDate: '',
        modalPhotoSrc: '',
        modalPhotoComment: '',
        modules: [Navigation],
        swiperObj: null,
        showSlide: false,
        currentModalPhoto: 0,
        modalPhotos: [],

        name: '',
        caps: {},
        plan: '',
        photos: {},
        report: {},
        klkt: {},
        scan: {},
        doctorPhotos: {},
        comment: '',
        threeDSetup: {},
        progressPhotos: {},
      showAddPhoto: false,
      showAddPrint: false,
      maxSize: {
        changesFiles: 99*1024*1024,
        klkt: 99*1024*1024,
        photos: 2*1024*1024*1024,
        report: 99*1024*1024,
        scan: 99*1024*1024,
        threeDSetup: 99*1024*1024,
        doctorPhotos: 99*1024*1024,
        addProgressPhoto: 99*1024*1024,
      },
      extention: {
        changesFiles: ['jpg','png', 'jpeg', 'tiff'],
        klkt: ['zip','rar', '7z'],
        photos: ['mp4', 'mov', 'mkv', 'avi', 'flv', 'jpg','png', 'jpeg', 'tiff', 'gif', 'pdf'],
        report: ['docx','pdf', 'xlsx', 'jpeg'],
        scan: ['zip','rar', '7z'],
        threeDSetup: ['pdf'],
        doctorPhotos: ['jpg','png', 'jpeg', 'tiff'],
        addProgressPhoto: ['jpg','png', 'jpeg', 'tiff'],
      },
      copyClass: {
        changesFiles: false,
        klkt: false,
        photos: false,
        report: false,
        scan: false,
        threeDSetup: false,
        doctorPhotos: false,
        addProgressPhoto: false,
      },
      error: false,
      error_text: '',
      sending: false,
      isSuccess: false,
      capRequest: {
          plus: [],
          minus: []
      },
      printComment: '',
      updrender: 1,
      addProgressPhoto: [],
      addProgressDate: '',
      addProgressComment: '',
      pdf: false,
      pdfPhoto1: [],
      pdfPhoto2: [],
      random: false,
      shareLink: {}
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {
      this.$store.commit('breadcrumb_page', '');
    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
  watch: {
    plan: function (newVal, oldVal) {
      if (this.plan.length > 2) {
        let myTextArea = this.$refs['planTextarea'];
        if (myTextArea) {
          myTextArea.rows = 2; // depends on what you want initially
          const styles = window.getComputedStyle(myTextArea);
          const paddingTop = parseInt(styles.paddingTop);
          const paddingBottom = parseInt(styles.paddingBottom);
          const padding = paddingTop + paddingBottom;
          const currentHeight = parseInt(styles.height) - padding;
          const initialHeight =
              (parseInt(styles.height) - padding) / myTextArea.rows;
          const scrollHeight = myTextArea.scrollHeight - padding;
          const newRows = Math.ceil(scrollHeight / initialHeight);
          myTextArea.rows = newRows;
        }
      }
    }
  },
    async mounted() {
        await this.get();
    },
    components: {
        Swiper,
        SwiperSlide,
        Notfound
    },
    methods: {
      async get() {
          let _this = this;

          await axios.get('/local/api/patient/detail/'+this.$route.params.id).then(function (response) {
            _this.load = false;
            _this.name = response.data.name
            _this.caps = response.data.caps;
            _this.plan = response.data.plan;
            _this.photos = response.data.photos;
            _this.report = response.data.report;
            _this.klkt = response.data.klkt;
            _this.scan = response.data.scans;
            _this.doctorPhotos = response.data.doctor_photos;
            _this.comment = response.data.comment;
            _this.threeDSetup = response.data.setup;
            _this.progressPhotos = response.data.progress_photos;
            _this.shareLink = response.data.shareLink;
            _this.$store.commit('breadcrumb_page', response.data.name);
          }).catch(function(err) {
            _this.$router.push('/404');
          });
        },
      openTab: function (id) {
            for (let i in this.tabs) {
                this.tabs[i] = false;
            }

            this.tabs[id] = true;
        },
      isFilled: function (e) {
            if (e.target.value != '') {
                e.target.classList.add('is-filled')
            } else {
                e.target.classList.remove('is-filled')
            }
        },
      setSwiperObj: function(swiper) {
            this.swiperObj = swiper;
        },
      modalPhoto: function(data, id) {
            this.modalPhotos = data;
            this.currentModalPhoto = id;
            if (this.swiperObj != null)
                this.swiperObj.slideTo(id);
            this.showSlide = true;
        },
      onSlideChange: function(swiper) {
            this.currentModalPhoto = swiper.realIndex;
        },
      formatBytes: function(size) {
            let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
            return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['б', 'кб', 'Мб', 'Гб', 'Тб'][i];
        },
      addFiles: function(e) {
        if (this.$refs[e].getAttribute('multiple')==null) {
          this.$data[e] = [];
        }
        let time = Date.now();
        for (let i = 0; i < this.$refs[e].files.length; i++) {
          let file = this.$refs[e].files[i];

          let error = this.checkSize(file, e);
          if (error)
            file.error = error;
          else {
            error = this.checkExtention(file, e);
            if (error)
              file.error = error;
          }


          file.format_size = this.formatBytes(file.size);
          file.filename = file.name;
          file.save = 0;
          file.progress = 0;
          file.rand = Math.floor(Math.random() * 999999);
         /* let reader  = new FileReader();
          reader.addEventListener("load", function (e, filename, file, res) {
            this.$data[e][this.$data[e].length-1].link = res.target.result
            this.upd();
          }.bind(this, e, file.name, file), false);
          reader.readAsDataURL( file );*/

          this.$data[e].push({filename: file.name, save: 0, progress: 0, link: '', rand: file.rand});
          this.loadFile(file, e, file.rand)
        }
      },
      loadFile(file, e, rand_id) {
        let _this = this;
        let formData = new FormData();
        formData.append('file', file);
        formData.append('rand', file.rand);
        axios.post( '/local/api/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: function(progressEvent) {
                for (let i in _this.$data[e]) {
                  if (_this.$data[e][i].rand == rand_id) {
                    _this.$data[e][i].progress = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100));
                    break;
                  }
                }
              }
            }
        ).then(function(response){
          if (response.data) {
            for (let i in _this.$data[e]) {
              if (_this.$data[e][i].rand == rand_id) {
                _this.$data[e][i].progress = 100;
                //_this.$data[e][i].save = 1;
                _this.$data[e][i].id = response.data.file.id;
                _this.$data[e][i].link = response.data.file.link;
                _this.$data[e][i].format_size = response.data.file.format_size;
                break;
              }
            }
          }
          else {

          }
        })
            .catch(function(){

            });
      },
      upd: function() {
        this.random = Math.random();
      },
      checkSize: function(file, code) {
        let error = '';
        if (this.maxSize[code] < file.size)
          error = 'Превышен размер файла';
        return error;
      },
      checkExtention: function(file, code) {
        let error = '';
        if (!this.extention[code].includes(file.name.split('.')[file.name.split('.').length-1].toLowerCase()))
          error = 'Недопустимое расширение файла';
        return error;
      },
      deleteFile: function(code, key) {
        debugger;
        let i=0;
        let index;
        for (let k in this.$data[code]) {
          debugger;
          if (k==key) {
            index = i;
          }
          i++;
        }
        this.$data[code].splice(index, 1);
        //delete this.$data[code][key];
        return false;
      },
      send: function(e) {
        if (this.sending === true)
          return;
          let form = e.target,
              formData = new FormData(),
              _this = this;

          this.error = [];

          formData.append('changesComment', _this.changesComment);
          let i;
          for( i in this.changesFiles){
            let file = this.changesFiles[i];
            if (file.error) {
              this.error.push('changesFiles');
            }
            formData.append('changesFiles[' + i + ']', file.id);
          }

        if (!this.changesComment)
          this.error.push('changesComment');


          if (this.error.length == 0) {
            this.sending = true;
            axios.post( '/local/api/patient/changeplan/'+this.$route.params.id,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
            ).then(function(response){
              if (response.data.id) {
                //_this.$router.push('/order/new/'+response.data.id)
                _this.changesFiles = {};
                _this.changesComment = '';
                _this.sending = false;
                _this.showChangesModal = false;
                _this.isSuccess = true;
              }
              else {
                _this.error_text = response.data.error;
                _this.sending = false;
              }
            })
                .catch(function(){
                  _this.sending = false;
                });

          }
      },
      sendPrint: function(e) {
        if (this.sending === true)
          return;
        let form = e.target,
            formData = new FormData(),
            _this = this;

        this.error = [];

        formData.append('comment', _this.printComment);
        formData.append('plus', _this.capRequest.plus);
        formData.append('minus', _this.capRequest.minus);

       /* if (!this.printComment)
          this.error.push('printComment');*/
        if (this.capRequest.plus.length==0 && this.capRequest.minus.length==0)
          this.error.push('cap');

        if (this.error.length == 0) {
          this.sending = true;
          axios.post( '/local/api/patient/addprint/'+this.$route.params.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.id) {
              //_this.$router.push('/order/new/'+response.data.id)
              _this.capRequest = {plus:[], minus:[]};
              _this.changesComment = '';
              _this.sending = false;
              _this.showAddPrint = false;
              _this.isSuccess = true;
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });

        }
      },
      addProgress: function(e) {
        if (this.sending === true)
          return;
        let form = e.target,
            formData = new FormData(),
            _this = this;

        this.error = [];
        formData.append('date', this.addProgressDate);
        formData.append('comment', this.addProgressComment);
        let i;
        for( i in this.addProgressPhoto){
          let file = this.addProgressPhoto[i];
          if (file.error) {
            this.error.push('photo');
          }
          formData.append('addProgressPhoto', file.id);
        }


        if (this.error.length == 0) {
          this.sending = true;
          axios.post( '/local/api/patient/addprogress/'+this.$route.params.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.id) {
              //_this.$router.push('/order/new/'+response.data.id)
             _this.showAddPhoto = false;
              _this.sending = false;
              _this.addProgressDate = '';
              _this.addProgressComment = '';
              _this.addProgressPhoto= [];
              _this.progressPhotos = response.data.progress_photos;
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });
          //this.success();
        }
      },
      deleteProgressPhoto: function(id, key) {
        if (this.sending === true)
          return;
        let formData = new FormData(),
            _this = this;

        this.error = [];
        formData.append('id', id);

        if (this.error.length == 0) {
          this.sending = true;
          axios.post( '/local/api/patient/deleteprogress/'+this.$route.params.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.status == 'OK') {
              _this.sending = false;
              let i=0;
              let index;
              for (let k in _this.progressPhotos) {
                if (k==key) {
                  index = i;
                }
                i++;
              }
              _this.progressPhotos.splice(index, 1);
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });
          //this.success();
        }

      },
      saveDocs: function(e) {
        debugger;
        if (this.sending === true)
          return;
        let form = e.target,
            formData = new FormData(),
            _this = this;

        this.error = [];
        formData.append('comment', this.comment);
        let i;
        for( i in this.klkt){
          let file = this.klkt[i];
          if (file.error) {
            this.error.push('klkt');
          }
          if (file.save == 1)
            formData.append('klkt_save[' + i + ']', file.id);
          else
            formData.append('klkt[' + i + ']', file.id);
        }
        for( i in this.scan){
          let file = this.scan[i];
          if (file.error) {
            this.error.push('scan');
          }
          if (file.save == 1)
            formData.append('scan_save[' + i + ']', file.id);
          else
            formData.append('scan[' + i + ']', file.id);
        }
        for( i in this.doctorPhotos){
          let file = this.doctorPhotos[i];
          if (file.error) {
            this.error.push('doctorPhotos');
          }
          if (file.save == 1)
            formData.append('doctorPhotos_save[' + i + ']', file.id);
          else
            formData.append('doctorPhotos[' + i + ']', file.id);
        }


        if (this.error.length == 0) {
          this.sending = true;
          axios.post( '/local/api/patient/docedit/'+this.$route.params.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.id) {
              //_this.$router.push('/order/new/'+response.data.id)
              _this.doc_edit = false;
              _this.sending = false;
              _this.klkt = response.data.klkt;
              _this.scan = response.data.scans;
              _this.doctorPhotos = response.data.doctor_photos;
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });
          //this.success();
        }
      },
      saveTreatment: function(e) {
        if (this.sending === true)
          return;
        let form = e.target,
            formData = new FormData(),
            _this = this;

        this.error = [];
        formData.append('plan', this.plan);
        formData.append('caps_plus', Object.keys(this.caps.plus).length);
        formData.append('caps_minus', Object.keys(this.caps.minus).length);
        let i;
        for( i in this.photos){
          let file = this.photos[i];
          if (file.error) {
            this.error.push('photos');
          }
          if (file.save == 1) {
            formData.append('photos_save[' + i + ']', file.id);
          }
          else {
            formData.append('photos[' + i + ']', file.id);
          }
        }
        for( i in this.report){
          let file = this.report[i];
          if (file.error) {
            this.error.push('report');
          }
          if (file.save == 1)
            formData.append('report_save[' + i + ']', file.id);
          else
            formData.append('report[' + i + ']', file.id);
        }
        /*for( i in this.doctorPhotos){
          let file = this.doctorPhotos[i];
          if (file.error) {
            this.error.push('doctorPhotos');
          }
          if (file.save == 1)
            formData.append('photo_save[' + i + ']', file.link);
          else
            formData.append('photo[' + i + ']', file);
        }
*/

        if (this.error.length == 0) {
          this.sending = true;
          axios.post( '/local/api/patient/treatmentedit/'+this.$route.params.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.id) {
              //_this.$router.push('/order/new/'+response.data.id)
              _this.treatment_edit = false;
              _this.sending = false;
              //_this.klkt = response.data.klkt;
              _this.report = response.data.report;
              _this.photos = response.data.photos;
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });
          //this.success();
        }
      },
      setupSave: function(e) {
        if (this.sending === true)
          return;
        let form = e.target,
            formData = new FormData(),
            _this = this;

        this.error = [];
        let i;
        for( i in this.threeDSetup){
          let file = this.threeDSetup[i];
          if (file.error) {
            this.error.push('setup');
          }
          if (file.save == 1)
            formData.append('threeDSetup_save[' + i + ']', file.id);
          else
            formData.append('threeDSetup[' + i + ']', file.id);
        }

        if (this.error.length == 0) {
          this.sending = true;
          axios.post( '/local/api/patient/setupedit/'+this.$route.params.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.id) {
              _this.setup_edit = false;
              _this.sending = false;
              _this.threeDSetup = response.data.setup;
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });
          //this.success();
        }
      },
      capMinusAdd() {
        let n = Object.keys(this.caps.minus).length;
        this.caps.minus[n+1] = 'n';
      },
      capPlusAdd() {
        debugger;
        let n = Object.keys(this.caps.plus).length;
        this.caps.plus[n+1] = 'n';
      },
      capMinusRemove(id) {
        if (!this.treatment_edit)
          return;
        let n;
        let items = {};
        let i = 0;
        for (n in this.caps.minus) {
          if (n != id) {
            i++;
            items[i] = this.caps.minus[n];
          }
        }
        this.caps.minus = items;
      },
      capPlusRemove(id) {
        if (!this.treatment_edit)
          return;
        let n;
        let items = {};
        let i = 0;
        for (n in this.caps.plus) {
          if (n != id) {
            i++;
            items[i] = this.caps.plus[n];
          }
        }
        this.caps.plus = items;
      },
      cancel() {
        debugger;
        this.treatment_edit = false;
        this.get();
      },
      close: function() {
        this.isSuccess = false;
      },
      addToRequest: function(id, type, status) {
        if (!status) {
          if (this.capRequest[type].includes(id)) {
            var index = this.capRequest[type].indexOf(id);
            if (index !== -1) {
              this.capRequest[type].splice(index, 1);
            }
          }
          else
            this.capRequest[type].push(id);
        }
      },
      getCapRequestStatus: function(id, type, status) {
        if (!status) {
          if (this.capRequest[type].includes(id))
            status = 'request';
        }
        return 'is-'+status;
      },
      createPdf() {
        this.pdf = true;
      },
      selectPhoto(photo, key) {
        if (this.$data[photo][key].select)
          this.$data[photo][key].select = false;
        else
          this.$data[photo][key].select = true;
      },
      loadPdf: function(e) {
        if (this.sending === true)
          return;
        let formData = new FormData(),
            _this = this;

        this.error = [];
        let i;

        debugger;

        console.log(this.doctorPhotos)
        console.log(this.photos)
        for (i in this.doctorPhotos) {
          if (this.doctorPhotos[i].select) {
            formData.append('doctorPhotos[' + i + ']', this.doctorPhotos[i].id);
          }
        }
        for (i in this.photos) {
          if (this.photos[i].select) {
            formData.append('photos[' + i + ']', this.photos[i].id);
          }
        }

        if (this.error.length == 0) {
          this.sending = true;
          axios.post( '/local/pdf/load/?id='+this.$route.params.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.url) {
              window.open(response.data.url, '_blank');
              _this.sending = false;
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });
          //this.success();
        }
      },
      copyLink: function(section) {
        let url = this.shareLink[section];
        let _this = this;
        navigator.clipboard.writeText(url).then(function() {
          console.log('Текст успешно скопирован в буфер обмена');
          _this.copyClass[section] = true;
        }, function(err) {
          console.error('Произошла ошибка при копировании текста: ', err);
        });

        setTimeout(function () {
          _this.copyClass[section] = false;
        }, 1000)
      }
    }
}
</script>