<template>
    <h1 class="title">Оплаты</h1>
    <div class="row">
        <div class="search">
            <input v-model="search" v-on:change="doSearch" v-on:keyup="doSearch" class="form-control search__input" type="text" placeholder="Поиск по ФИО или дате (ДД.ММ.ГГГГ)">
        </div>
        <div v-if="role=='buhgalter' || role=='admin'" class="btn btn--big btn--primary btn--icon js-open-modal" data-modal="add-payment" @click="isOpen=true">
            <span>Внести оплату</span>
            <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
            </svg>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-payment">
                    <div class="table-head">
                        <div class="table-cell">Номер</div>
                        <div class="table-cell table-cell--center">Дата</div>
                        <div class="table-cell table-cell--center">Сумма</div>
                        <div class="table-cell">Пациент</div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'/payments-patient/'+item.patient_id" v-for="(item, id) in items">
                        <div class="table-cell">#{{ item.id }}</div>
                        <div class="table-cell">{{ item.date }}</div>
                        <div class="table-cell table-cell--right">{{ item.sum }} ₽</div>
                        <div class="table-cell table-cell--light">{{ item.patient }}</div>
                        <div class="table-cell">
                            <div class="table-cell__action">
                                <div class="edit js-open-modal" data-modal="edit-payment" @click.prevent="editPayment(item)">
                                    <svg width="30" height="30">
                                        <use xlink:href="@/images/sprite.svg#edit"></use>
                                    </svg>
                                </div>
                                <div class="remove js-open-modal" data-modal="remove-payment" @click.prevent="delete_id = item.id; isDelete=true">
                                    <svg width="30" height="30">
                                        <use xlink:href="@/images/sprite.svg#remove"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
  <Pagination v-if="page.num_page > 1" @gotopage="gotopage" :pagination="page"></Pagination>

    <div class="modal" :class="isOpen?'is-open':''" data-modal="add-payment">
        <div class="modal-overlay" @click="isOpen=false; edit_id = 0; edit_sum=''; edit_date = ''; clinic=''; doctor=''; patient=''; patient_id= ''"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="isOpen=false; edit_id = 0; edit_sum=''; edit_date = ''; clinic=''; doctor=''; patient=''; patient_id= ''">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <PaymentForm :key="edit_id?edit_id:'new'" @close="isOpen = false; edit_id = 0; edit_sum=''; edit_date = ''; clinic=''; doctor=''; patient=''; patient_id= ''" @success="gotopage(1); isOpen = false; isSuccess = true;" :cur_clinic="clinic" :cur_doctor="doctor" :cur_patient="patient" :cur_patient_id="patient_id" :cur_id="edit_id"  :cur_sum="edit_sum"  :cur_date="edit_date"></PaymentForm>
            </div>
        </div>
    </div>

    <div class="modal" :class="isSuccess?'is-open':''" data-modal="3">
        <div class="modal-overlay" @click="isSuccess = false"> </div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close">
                    <svg width="30" height="30">
                        <use xlink:href="images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Оплата успешно внесена!</div>
                <div class="modal-action">
                    <div class="btn btn--primary js-modal-close" @click="isSuccess = false">Ок</div>
                </div>
            </div>
        </div>
    </div>

  <div class="modal" :class="isDelete ? 'is-open' : ''" data-modal="print-delete">
    <div class="modal-overlay" @click="isDelete = false"></div>
    <div class="modal-wrapper">
      <div class="modal-content">
        <div class="modal-close" @click="isDelete = false">
          <svg width="30" height="30">
            <use xlink:href="@/images/sprite.svg#close"></use>
          </svg>
        </div>
        <div class="modal__title">Удалить оплату?
          <div class="modal-action">
            <div class="btn js-modal-close" @click="deletePayment">Да</div>
            <div class="btn btn--primary js-modal-close" @click="isDelete = false; delete_id=0">Нет
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentForm from '../components/app/PaymentForm';
import axios from "axios";
import Pagination from "@/components/app/Pagination"

export default {
    data: () => ({
        items: {},
        isOpen: false,
        isSuccess: false,
      pagination: {},
      search: '',
      searching: false,
      isDelete: false,
      delete_id: 0,
      edit_id: 0,
      edit_sum: '',
      edit_date: '',
      patient: '',
      patient_id: '',
      clinic: '',
      doctor: ''
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      page() {
        return this.pagination;
      },
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {
        this.gotopage(1);
    },
    components: {
        PaymentForm, Pagination
    },
    methods: {
      async gotopage(page, add=false) {
        let _this = this;
        let url = '/local/api/payments/list?page='+page;
        if (this.search)
          url += "&search="+this.search;
        await axios.get(url).then(function (response) {
          if (add == true)
            _this.items = _this.merge(_this.items, response.data.items);
          else
            _this.items = response.data.items;
          _this.pagination = response.data.pagination;
          _this.searching = false;
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      },
      merge(arr1, arr2) {
        let item, items = [];
        for (item in arr1) {
          items.push(arr1[item]);
        }
        for (item in arr2) {
          items.push(arr2[item]);
        }
        return items;
      },
      async doSearch()
      {
        if (this.searching) {
          console.log('searching')
          return;
        }
        let val = this.search;
        if (val.length < 3 && val != '')
          return;
        /*if (this.searching == true)
          return;*/
        this.searching = true;
        await this.gotopage(1);
      },
      editPayment(item) {
       this.edit_id = item.id;
        this.edit_sum=item.sum;
        this.edit_date = item.date;
        this.patient  = item.patient;
        this.patient_id  = item.patient_id;
        this.doctor  = item.doctor;
        this.clinic = item.clinic;
        console.log(item)
        this.isOpen=true;
      },
      deletePayment() {
        let _this = this;
        let url = '/local/api/payments/delete/'+this.delete_id;
        axios.get(url).then(function (response) {
          _this.gotopage(1);
          _this.isDelete = false;
          _this.delete_id=0;
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      }
    },
}
</script>

