<template>
    <h1 class="title">Лидеры продаж</h1>
    <SalesLiderMenu></SalesLiderMenu>
    <div class="row">
        <div class="search">
            <input v-model="search" v-on:change="doSearch" v-on:keyup="doSearch" class="form-control search__input" type="text" placeholder="Поиск по ФИО">
        </div>
    </div>
    <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <div class="table table-patient">
              <div class="table-head">
                <div class="table-cell">Врач</div>
                <div class="table-cell table-cell--center">Пациентов</div>
                <div class="table-cell"></div>
              </div>
              <div class="table-row" v-for="(item, id) in items">
                <div class="table-celll table-cell--light">
                  <svg v-if="item.num < 3" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0L12.8331 6.10054L19.5106 6.90983L14.5841 11.4895L15.8779 18.0902L10 14.82L4.12215 18.0902L5.41591 11.4895L0.489435 6.90983L7.16687 6.10054L10 0Z" :fill="color[item.num]"/>
                  </svg>
                  {{ item.name }}</div>
                <div class="table-cell table-cell--center">{{ item.patients }}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  <Pagination v-if="page.num_page > 1" @gotopage="gotopage" :pagination="page"></Pagination>
</template>

<script>

import axios from "axios";
import Pagination from "@/components/app/Pagination"
import SalesLiderMenu from "@/components/app/SalesLiderMenu"

export default {
    data: () => ({
        items: {},
        pagination: {},
      search: '',
      searching: false,
      color: [
          '#FDC604',
          '#B1B1B1',
          '#A15116'
      ]
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      page() {
        return this.pagination;
      }
    },
    async mounted() {
        this.gotopage(1);
    },
    components: {Pagination, SalesLiderMenu},
    methods: {
      async gotopage(page, add=false) {
        let _this = this;
        let url = '/local/api/saleslider?page='+page;
        if (this.search)
          url += "&search="+this.search;
        await axios.get(url).then(function (response) {
          if (add == true)
            _this.items = _this.merge(_this.items, response.data.items);
          else
            _this.items = response.data.items;
          //_this.pagination = response.data.pagination;
          _this.searching = false;
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      },
      merge(arr1, arr2) {
        let item, items = [];
        for (item in arr1) {
          items.push(arr1[item]);
        }
        for (item in arr2) {
          items.push(arr2[item]);
        }
        return items;
      },
      async doSearch()
      {
        if (this.searching) {
          console.log('searching')
          return;
        }
        let val = this.search;
        if (val.length < 3 && val != '')
          return;
        /*if (this.searching == true)
          return;*/
        this.searching = true;
        await this.gotopage(1);
      }
    },
}
</script>