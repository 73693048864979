<template>
    <h1 class="title">Лидеры продаж</h1>
    <SalesLiderMenu></SalesLiderMenu>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-patient">
                    <div class="table-head">
                        <div class="table-cell">Клиника</div>
                        <div class="table-cell table-cell--center">Пациентов</div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'/sales_lider-clinic/'+item.id" v-for="(item, id) in items">
                        <div class="table-celll table-cell--light">{{ item.name }}</div>
                        <div class="table-cell table-cell--center">{{ item.patients }}</div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import SalesLiderMenu from "@/components/app/SalesLiderMenu"

export default {
    data: () => ({
        items: {},
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {
        let _this = this;

        await axios.get('/local/api/saleslider/clinic').then(function (response) {
            _this.items = response.data.items

        }).catch(function(err) {
          _this.$router.push('/404');
        });
    },
    components: {SalesLiderMenu},
    methods: {

    },
}
</script>