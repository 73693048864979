<template>
  <div class="tabs">
    <router-link class="tabs-item is-new" active-class="is-active" to="/">Новые<span>{{ newRequest }}</span></router-link>
    <router-link class="tabs-item" active-class="is-active" to="/orders-archive">Обработанные<span>{{patient}}</span></router-link>
  </div>
</template>

<script>
export default {
    data: () => ({

    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {},
    computed: {
      newRequest() {
        return this.$store.getters.requestNumbers?this.$store.getters.requestNumbers.newRequest:'';
      },
      patient() {
        return this.$store.getters.requestNumbers?this.$store.getters.requestNumbers.patient:'';
      },
      print() {
        return this.$store.getters.requestNumbers?this.$store.getters.requestNumbers.print:'';
      },
      plan() {
        return this.$store.getters.requestNumbers?this.$store.getters.requestNumbers.plan:'';
      }
    },
    async mounted() {
      //if (!this.$store.getters.requestNumbers)
        await this.$store.dispatch('fetchRequestNumbers')
    },
    components: {},
    methods: {}
}
</script>
