<template>
    <h1 class="title">{{clinic}}</h1>
  <PatientMenu></PatientMenu>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-patient">
                    <div class="table-head">
                        <div class="table-cell">Врач</div>
                        <div class="table-cell table-cell--center">Пациенты</div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'/doctor-patients/'+item.id" v-for="(item, id) in items">
                        <div class="table-celll table-cell--light">{{item.name}}</div>
                        <div class="table-cell table-cell--center">{{item.patients}}</div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import PatientMenu from "@/components/app/PatientMenu"

export default {
    data: () => ({
      clinic: '',
      items: {},
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {
      this.$store.commit('breadcrumb_page', '');
    },
    computed: {

    },
    async mounted() {
      let _this = this;

      await axios.get('/local/api/patient/clinic/'+this.$route.params.id).then(function (response) {
        _this.clinic = response.data.clinic;
        _this.items = response.data.items;
        _this.$store.commit('breadcrumb_page', response.data.clinic);

      }).catch(function(err) {
        _this.$router.push('/404');
      });
    },
    components: {PatientMenu},
    methods: {

    },
}
</script>