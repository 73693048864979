<template>
    <h1 class="title">Долги</h1>
    <DeptMenu></DeptMenu>
    <div class="row">
        <div class="dept-price">
            <div class="dept-price__title">Долг</div>
            <div class="dept-price__num">{{ sumCurrent }} ₽<span>{{ sumTotal }} ₽</span></div>
        </div>
        <div v-if="role=='buhgalter' || role=='admin'" class="btn btn--icon btn--big js-open-modal" data-modal="add-payment" @click="isOpen=true">
            <span>Внести оплату</span>
            <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
            </svg>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-dept">
                    <div class="table-head">
                        <div class="table-cell">Клиника</div>
                        <div class="table-cell table-cell--center">Долг</div>
                        <div class="table-cell table-cell--right">Общая сумма</div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'/depts-clinic/'+item.clinic_id" v-for="(item, id) in items">
                        <div class="table-celll table-cell--light">{{ item.name }}</div>
                        <div class="table-cell table-cell--right">{{ item.sumCurrent }} ₽</div>
                        <div class="table-cell table-cell--right">{{ item.sumTotal }} ₽</div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" :class="isOpen?'is-open':''" data-modal="add-payment">
        <div class="modal-overlay" @click="isOpen=false"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="isOpen=false">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <PaymentForm @close="isOpen = false" @success="get(); isOpen = false; isSuccess = true;"></PaymentForm>
            </div>
        </div>
    </div>

    <div class="modal" :class="isSuccess?'is-open':''" data-modal="3">
        <div class="modal-overlay" @click="isSuccess = false"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close">
                    <svg width="30" height="30">
                        <use xlink:href="images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Оплата успешно внесена!</div>
                <div class="modal-action">
                    <div class="btn btn--primary js-modal-close" @click="isSuccess = false">Ок</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentForm from '../components/app/PaymentForm';
import axios from "axios";
import DeptMenu from "@/components/app/DeptMenu"

export default {
    data: () => ({
        isOpen: false,
        isSuccess: false,
        sumCurrent: '',
        sumTotal: '',
        items: {},
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {
      this.get();
    },
    components: {
        PaymentForm, DeptMenu
    },
    methods: {
      async get() {
        let _this = this;

        await axios.get('/local/api/depts/groups').then(function (response) {
          _this.sumCurrent = response.data.sumCurrent;
          _this.sumTotal = response.data.sumTotal;
          _this.items = response.data.items;
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      }
    },
}
</script>

