<template>
    <h1 class="title">{{ doctor }}</h1>
  <div class="row">
    <div class="search">
      <input v-model="search" v-on:change="doSearch" v-on:keyup="doSearch" class="form-control search__input" type="text" placeholder="Поиск по ФИО">
    </div>
  </div>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-patient-single">
                    <router-link class="table-row" :to="'/patient/'+id" v-for="(item, id) in items">
                        <div class="table-celll table-cell--light">{{ item.name }}</div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
  <Pagination v-if="page.num_page > 1" @gotopage="gotopage" :pagination="page"></Pagination>
</template>

<script>

import axios from "axios";
import Pagination from "@/components/app/Pagination"

export default {
    data: () => ({
        doctor: '',
        items: {},
      pagination: {},
      search: '',
      searching: false
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {
      this.$store.commit('breadcrumbs', false);
      this.$store.commit('breadcrumb_page', '');
    },
  computed: {
    page() {
      return this.pagination;
    }
  },
    async mounted() {
      this.gotopage(1);
       /* let _this = this;
        let id = '';
        if (this.$store.getters.role == 'doctor')
          id = '0';
        else
          id = this.$route.params.id

      let url = '/local/api/patient/list?page='+page;
      if (this.search)
        url += "&search="+this.search;

        await axios.get('/local/api/patient/doctor/'+id).then(function (response) {
            _this.doctor = response.data.doctor;
            _this.items = response.data.items;
          _this.$store.commit('breadcrumbs', [{url:'/patients-clinic/'+response.data.clinic_id, name:response.data.clinic}]);
          _this.$store.commit('breadcrumb_page', response.data.doctor);
        }).catch(function(err) {
          _this.$router.push('/404');
        });*/
    },
    components: {Pagination},
    methods: {
      async gotopage(page, add=false) {
        let _this = this;

        let id = '';
        if (this.$store.getters.role == 'doctor')
          id = '0';
        else
          id = this.$route.params.id

        let url = '/local/api/patient/doctor/'+id+'?page='+page;
        if (this.search)
          url += "&search="+this.search;
        await axios.get(url).then(function (response) {
          if (add == true)
            _this.items = _this.merge(_this.items, response.data.items);
          else
            _this.items = response.data.items;
          _this.doctor = response.data.doctor;
          _this.pagination = response.data.pagination;
          _this.$store.commit('breadcrumbs', [{url:'/patients-clinic/'+response.data.clinic_id, name:response.data.clinic}]);
          _this.$store.commit('breadcrumb_page', response.data.doctor);
          _this.searching = false;
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      },
      merge(arr1, arr2) {
        let item, items = [];
        for (item in arr1) {
          items.push(arr1[item]);
        }
        for (item in arr2) {
          items.push(arr2[item]);
        }
        return items;
      },
      async doSearch()
      {
        if (this.searching) {
          console.log('searching')
          return;
        }
        let val = this.search;
        if (val.length < 3 && val != '')
          return;
        /*if (this.searching == true)
          return;*/
        this.searching = true;
        await this.gotopage(1);
      }
    },
}
</script>