<template>
  <div class="content error">
    <h1 class="title">Страница не найдена</h1>
    <div class="error__title">Мы уже чиним это</div>
    <div class="error__btn btn btn--big btn--primary" @click="prev"> <span>Вернуться назад</span></div>
    <div class="error__num"><img src="@/images/num.svg" alt=""></div>
    <div class="error__img"> <img src="@/images/vortex.png" alt=""></div>
  </div>
</template>

<script>
export default {
  mounted() {
    //this.$store.dispatch('Loader');
  },
  components: {},
  methods: {
    prev() {
      this.$router.go(-1);
    }
  },
}
</script>

