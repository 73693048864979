import Main from './views/Main.vue'
import MainNotAuth from './views/MainNotAuth.vue'
import Login from './views/Login.vue'
import Notfound from './views/Notfound.vue'
import Print from './views/Print.vue'
import Depts from './views/Depts.vue'
import Order from './views/Order.vue'

import OrderChange from './views/OrderChange.vue'
import OrderPrint from './views/OrderPrint.vue'
import OrdersArchive from './views/OrdersArchive.vue'
import OrdersPrintArchive from './views/OrdersPrintArchive.vue'
import OrdersChangeArchive from './views/OrdersChangeArchive.vue'
import PrintReady from './views/PrintReady.vue'
import DeptsAll from './views/DeptsAll.vue'
import DeptsClinic from './views/DeptsClinic.vue'
import DeptsDoctor from './views/DeptsDoctor.vue'
import Patients from './views/Patients.vue'
import PatientsClinic from './views/PatientsClinic.vue'
import PatientsAll from './views/PatientsAll.vue'
import DoctorPatients from './views/DoctorPatients.vue'
import Patient from './views/Patient.vue'
import PatientNew from './views/PatientNew.vue'
import Payments from './views/Payments.vue'
import PaymentsPatient from './views/PaymentsPatient.vue'

import Progress from './views/Progress.vue'
import SalesLider from './views/SalesLider.vue'
import SalesLiderAll from './views/SalesLiderAll.vue'
import SalesLiderClinic from './views/SalesLiderClinic.vue'
import Awards from './views/Awards.vue'
import Files from './views/Files.vue'

import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import store from './store'

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuth) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    if (from.name == undefined) {
        next();
        return;
    }
    if (store.getters.isAuth) {
        next()
        return
    }
    next('/auth')
}

export default new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    //base: process.env.BASE_URL,
    routes: [
        {
            path: '/auth',
            component: MainNotAuth,
            name:'main-not-auth',
            meta: {title: 'Главная'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/login',
            component: Login,
            name:'login',
            meta: {title: 'Авторизация'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/',
            component: Main,
            name:'main',
            meta: {layout: 'main', name: 'Заявки'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/print',
            component: Print,
            name:'print',
            meta: {layout: 'main', type: 'work', name: 'Очередь на печать'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/print-ready',
            component: PrintReady,
            name:'printReady',
            meta: {layout: 'main', type: 'ready', name: 'Очередь на печать'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/depts',
            component: Depts,
            name:'depts',
            meta: {layout: 'main', name: 'Долги'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/depts-all',
            component: DeptsAll,
            name:'deptsAll',
            meta: {layout: 'main', name: 'Долги'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/depts-clinic/:id',
            component: DeptsClinic,
            name:'deptsClinic',
            meta: {layout: 'main', breadcrumbs:[
                    {
                        url: '/depts',
                        name: 'Долги'
                    }
                ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/depts-doctor/:id',
            component: DeptsDoctor,
            name:'deptsDoctor',
            meta: {layout: 'main', breadcrumbs_path:true, breadcrumbs:[
                    {
                        url: '/depts',
                        name: 'Долги'
                    }
            ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/order/:type/:id',
            component: Order,
            name:'order',
            meta: {layout: 'main', breadcrumbs:[
                    {
                        url: '/',
                        name: 'Заявки'
                    }
                ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/order-print/:type/:id',
            component: OrderPrint,
            name:'orderPrint',
            meta: {layout: 'main', breadcrumbs:[
                    {
                        url: '/',
                        name: 'Заявки'
                    }
                ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/order-change/:type/:id',
            component: OrderChange,
            name:'orderChange',
            meta: {layout: 'main', breadcrumbs:[
                    {
                        url: '/',
                        name: 'Заявки'
                    }
                ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/orders-archive',
            component: OrdersArchive,
            name:'ordersArchive',
            meta: {layout: 'main', name: 'Заявки на пациентов (архив)'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/orders-print-archive',
            component: OrdersPrintArchive,
            name:'ordersPrintArchive',
            meta: {layout: 'main', name: 'Заявки на печать (архив)'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/orders-change-archive',
            component: OrdersChangeArchive,
            name:'orderChangeArchive',
            meta: {layout: 'main', name: 'Заявки на изменение лечения (архив)'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/patients',
            component: Patients,
            name:'patients',
            meta: {layout: 'main', name: 'Пациенты'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/patients-clinic/:id',
            component: PatientsClinic,
            name:'patientsClinic',
            meta: {layout: 'main', breadcrumbs:[
                    {
                        url: '/patients',
                        name: 'Пациенты'
                    }
                ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/patients-all',
            component: PatientsAll,
            name:'patientsAll',
            meta: {layout: 'main', name: 'Пациенты'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/doctor-patients/:id',
            component: DoctorPatients,
            name:'doctorPatient',
            meta: {layout: 'main', breadcrumbs_path:true, breadcrumbs:[
                {
                    url: '/patients',
                    name: 'Пациенты'
                }
            ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/patient/:id',
            component: Patient,
            name:'patient',
            meta: {layout: 'main', breadcrumbs:[
                    {
                        url: '/patients',
                        name: 'Пациенты'
                    }
                ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/patientnew',
            component: PatientNew,
            name:'patientnew',
            meta: {layout: 'main', name: 'Новая заявка'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/payments',
            component: Payments,
            name:'payments',
            meta: {layout: 'main', name: 'Оплаты'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/payments-patient/:id',
            component: PaymentsPatient,
            name:'paymentsPatient',
            meta: {layout: 'main', breadcrumbs:[
                    {
                        url: '/payments',
                        name: 'Оплаты'
                    }
                ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/progress',
            component: Progress,
            name:'progress',
            meta: {layout: 'main', name: 'Достижения'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/sales_lider',
            component: SalesLider,
            name:'sales_lider',
            meta: {layout: 'main', name: 'Лидеры продаж'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/sales_lider-all',
            component: SalesLiderAll,
            name:'sales_lider-all',
            meta: {layout: 'main', name: 'Лидеры продаж'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/sales_lider-clinic/:id',
            component: SalesLiderClinic,
            name:'sales_lider-clinic',
            meta: {layout: 'main', name: 'Лидеры продаж', breadcrumbs:[
                    {
                        url: '/sales_lider',
                        name: 'Лидеры продаж'
                    }
                ]},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/awards',
            component: Awards,
            name:'awards',
            meta: {layout: 'main', name: 'Награды'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/file/:id/:section/:hash',
            component: Files,
            name:'Files',
            meta: {layout: 'empty'}
        },
        {
            path: '/:pathMatch(.*)*',
            component: Notfound,
            name:'notfound',
            meta: {layout: 'main', title: 'Ошибка'}
        },
    ]
})