<template>
  <main>
    <div class="auth">
      <div class="auth-video">
        <video src="@/images/video.mp4" playsinline muted autoplay loop> </video>
      </div>
      <div class="auth-btn btn btn--primary btn--big js-open-modal" data-modal="auth" @click="modalOpen = !modalOpen">Авторизоваться</div>
    </div>
  </main>
  <div class="modal" :class="modalOpen? 'is-open' : ''" data-modal="auth">
    <div class="modal-overlay" @click="modalOpen = false"> </div>
    <div class="modal-wrapper">
      <div class="modal-content">
        <div class="modal-close" @click="modalOpen = false">
          <svg width="30" height="30">
            <use xlink:href="@/images/sprite.svg#close"></use>
          </svg>
        </div>
        <div class="modal__title">Авторизация</div>
        <form class="modal-form modal-form--top" @submit.prevent="send">

          <div class="form-group">
            <input class="form-control" :class="error.includes('login') ? 'form-control--error' : ''" type="text" v-model="login" @input="isFilled">
            <label class="form-label" for="">Логин<span>*</span></label>
          </div>
          <div class="form-group">
            <input class="form-control" :class="error.includes('password') ? 'form-control--error' : ''" type="password" v-model="password" @input="isFilled">
            <label class="form-label" for="">Пароль<span>*</span></label>
          </div>
          <div v-if="error_text" v-html="error_text"></div>
          <div class="modal-action">
            <button class="btn btn--big">Войти в Личный кабинет</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: [
  ],
  data: () => ({
      modalOpen: false,
      error: [],
      login: '',
      password: '',
      error_text: '',
  }),
  created() {

  },
  computed: {

  },
  mounted() {

    //this.$store.dispatch('Loader');
  },
  beforeRouteLeave(to, from) {

    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
  methods: {
      isFilled: function(e) {
          let input;

          if (e instanceof Event) {
              input = e.target;
          } else {
              input = e;
          }

          if (input.value != '') {
              input.classList.add('is-filled')
          } else {
              input.classList.remove('is-filled')
          }
      },
      send: function(e) {
          let form = e.target,
              inputs = form.querySelectorAll('input'),
              formData = new FormData(),
              _this = this;

          this.error = [];

          formData.append('login', _this.login);
          formData.append('password', _this.password);

          // console.log(formData);

          for (const pair of formData.entries()) {
              if (pair[1] == '') {
                  this.error.push(pair[0]);
              }
          }

          inputs.forEach(function(input) {
              _this.isFilled(input);
          });

          if (this.error.length == 0) {
              console.log('ok');
            let _this = this;
            axios.post('/local/api/login', formData).then(response => {
              debugger;
              _this.error_text = '';
                  let data = response.data;
              if (data.is_auth === true) {
                /*_this.$store.dispatch('Auth', {
                  login: data.login,
                  token: data.token,
                  user: data.arUser,
                  uuid: data.uuid
                })
                _this.axios.defaults.params = {Authorization: data.token}*/
                _this.$store.dispatch('Auth', data)
                _this.$router.push(data.mainpage);
              }
              else {
                //_this.error.push('login')
                _this.error_text = data.error.MESSAGE;
                console.log(data)
              }
            });
          }
      },
  },
  components: {

  }
}
</script>

