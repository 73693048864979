<template>
    <h1 class="title">Очередь на печать</h1>
  <PrintMenu></PrintMenu>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-print">
                    <div class="table-head">
                        <div class="table-cell">Номер</div>
                        <div class="table-cell">Заявка</div>
                        <div class="table-cell">Поставлено</div>
                        <div class="table-cell">Статус</div>
                        <div class="table-cell">Пациент</div>
                        <div class="table-cell"></div>
                    </div>
                  <template v-for="(item, id) in items">
                    <div class="table-section acc" @click="togglePrintItem($event, id)" :data-id="id" v-if="!item.delete">
                        <div class="table-row">
                            <div class="table-cell acc-item"> #{{ id }}</div>
                            <div class="table-cell acc-item"> {{ item.dateCreated }}</div>
                            <div class="table-cell acc-item"> {{ item.dateAdded }}</div>
                            <div class="table-cell">
                                <select @change="changeStatus($event, id)" class="js-select">
                                    <option value="request" :selected="item.status == 'request'">Запрошен</option>
                                    <option value="work" :selected="item.status == 'work'">В работе</option>
                                    <option value="waiting" :selected="item.status == 'waiting'">Ждет отправки</option>
                                    <option value="ready" :selected="item.status == 'ready'">Готово</option>
                                </select>
                            </div>
                            <div class="table-cell acc-item">
                              <router-link :to="'/patient/'+item.patient_id">{{ item.patient }}</router-link>
                            </div>
                            <div class="table-cell table-button acc-item">
                                <div class="show-more">
                                    <svg width="7" height="14">
                                        <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <slide-up-down :active="openItems[id] == true">
                            <div class="table-content print-content acc-body">
                                <div class="print-head">
                                    <div class="print-mouthguards">
                                        <div class="mouthguards-row">
                                            <div class="mouthguards__symbol">
                                                <svg width="16" height="16">
                                                    <use xlink:href="@/images/sprite.svg#plus"></use>
                                                </svg>
                                            </div>
                                            <div class="mouthguards-list">
                                                <div class="mouthguards__item" :class="'is-'+status" v-for="(status, id) in item.caps.plus">{{ id }}</div>
                                            </div>
                                        </div>
                                        <div class="mouthguards-row">
                                            <div class="mouthguards__symbol">
                                                <svg width="16" height="16">
                                                    <use xlink:href="@/images/sprite.svg#minus"></use>
                                                </svg>
                                            </div>
                                            <div class="mouthguards-list">
                                                <div class="mouthguards__item" :class="'is-'+status" v-for="(status, id) in item.caps.minus">{{ id }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="role=='admin'" class="print-action">
                                        <div class="edit js-open-modal" data-modal="print-edit" @click="printEdit(id)">
                                            <svg width="30" height="30">
                                                <use xlink:href="@/images/sprite.svg#edit"></use>
                                            </svg>
                                        </div>
                                        <div class="remove js-open-modal" data-modal="print-delete"
                                             @click="deleteRequest(id)">
                                            <svg width="30" height="30">
                                                <use xlink:href="@/images/sprite.svg#remove"></use>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="print-inform" v-if="item.comment">
                                    <svg width="30" height="30">
                                        <use xlink:href="@/images/sprite.svg#chat"></use>
                                    </svg>
                                    {{ item.comment }}
                                </div>
                                <div v-if="item.history.length > 0" class="print-history">
                                    <div class="print-history__item" v-for="history in item.history">
                                        <div class="print-history__item-text">{{ history.date }} - {{ history.doctor }}
                                        </div>
                                        <div class="print-history__item-status" :data-value="history.statusCode">{{ history.statusName }}</div>
                                      <div v-if="history.comment">{{history.comment}}</div>
                                    </div>
                                </div>
                            </div>
                        </slide-up-down>
                    </div>
                  </template>
                </div>
            </div>
        </div>
    </div>
    <Pagination v-if="page && page.num_page > 1" @gotopage="gotopage" :pagination="page"></Pagination>

    <div class="modal" :class="printEditOpen ? 'is-open' : ''" data-modal="print-edit">
        <div class="modal-overlay" @click="printEditOpen = !printEditOpen"></div>
        <div class="modal-wrapper">
            <form class="modal-content" @submit.prevent="send">
                <input type="hidden" name="editId" v-model="printEditId">
                <div class="modal-close" @click="printEditOpen = !printEditOpen">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Редактировать запись в очереди</div>
                <div class="modal__desc">Если какие-то каппы не нужно печатать — отметьте их серым</div>
                <div class="modal-mouthguards">
                    <div class="mouthguards">
                        <div class="mouthguards-line">
                            <div class="mouthguards__symbol">
                                <svg width="16" height="16">
                                    <use xlink:href="@/images/sprite.svg#plus"></use>
                                </svg>
                            </div>
                            <div class="mouthguards-list">
                                <label @click.prevent="addToRequest('plus', key)" class="mouthguards__item js-mouthguards" :class="'is-'+status" v-for="(status, key) in printEditCaps.plus">
                                    <input class="hidden-input" type="checkbox" v-model="editCaps[printEditId+'_plus_'+key]">
                                    {{ key }}
                                </label>
                            </div>
                        </div>
                        <div class="mouthguards-line">
                            <div class="mouthguards__symbol">
                                <svg width="16" height="16">
                                    <use xlink:href="@/images/sprite.svg#minus"></use>
                                </svg>
                            </div>
                            <div class="mouthguards-list">
                                <label @click.prevent="addToRequest('minus', key)" class="mouthguards__item js-mouthguards" :class="'is-'+status" v-for="(status, key) in printEditCaps.minus">
                                    <input class="hidden-input" type="checkbox" v-model="editCaps[printEditId+'_minus_'+key]">
                                    {{ key }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-form modal-form--comments">
                    <div class="form-group">
                        <input v-model="editComment" class="form-control" type="text" placeholder="Комментарий">
                    </div>
                    <div class="modal-action">
                        <div class="btn js-modal-close" @click="printEditOpen = !printEditOpen">Отменить</div>
                        <button class="btn btn--primary js-modal-close">Сохранить</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal" :class="printDeleteOpen ? 'is-open' : ''" data-modal="print-delete">
        <div class="modal-overlay" @click="printDeleteOpen = false"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="printDeleteOpen = false">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Удалить запись из очереди?
                    <div class="modal-action">
                        <div class="btn js-modal-close" @click="deletePrint">Да</div>
                        <div class="btn btn--primary js-modal-close" @click="printDeleteOpen = false; delete_id=0">Нет
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" :class="printDeleteSuccess ? 'is-open' : ''" data-modal="print-deleted">
        <div class="modal-overlay" @click="printDeleteSuccess = false"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="printDeleteSuccess = false">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Запись успешно удалена!</div>
                <div class="modal-action">
                    <div class="btn btn--primary js-modal-close" @click="printDeleteSuccess = false">Ок</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import axios from 'axios';
import SlideUpDown from 'vue-slide-up-down';
import PrintMenu from "@/components/app/PrintMenu"
import Pagination from "@/components/app/Pagination"

export default {
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    data: () => ({
        items: {},
        openItems: {},
        printEditCaps: {},
        printEditId: '',
        caps: {},
        printEditOpen: false,
        printDeleteOpen: false,
        printDeleteSuccess: false,

        editCaps: {},
        editComment: '',
        pagination: {},
      delete_id: 0
    }),
    created() {

    },
    computed: {
      page() {
        return this.pagination;
      },
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {
      this.gotopage(1);
    },
    components: {
        SlideUpDown, PrintMenu, Pagination
    },
    methods: {
      async gotopage(page, add=false) {
        let _this = this;
        await axios.get('/local/api/print/'+this.$route.meta.type+'?page='+page).then(function (response) {
          if (add == true)
            _this.items = _this.merge(_this.items, response.data.items);
          else
            _this.items = response.data.items;
          _this.pagination = response.data.pagination?response.data.pagination:false;
          for (const [key, value] of Object.entries(response.data.items)) {
            _this.openItems[key] = false;

            for (const [keyPlus, valuePlus] of Object.entries(value.caps.plus)) {
              _this.caps[key+'_plus_'+keyPlus] = true;
            }

            for (const [keyMinus, valueMinus] of Object.entries(value.caps.minus)) {
              _this.caps[key+'_minus_'+keyMinus] = true;
            }
          }
        }).catch(function(err) {
          _this.$router.push('/404');
        });

        let selectList = document.querySelectorAll('.js-select');

        selectList.forEach(function (item) {
          let choices = new Choices(item, {shouldSort: false});
        });
      },
      merge(arr1, arr2) {
        let item, items = [];
        for (item in arr1) {
          items.push(arr1[item]);
        }
        for (item in arr2) {
          items.push(arr2[item]);
        }
        return items;
      },
        togglePrintItem: function (e, id) {
            let selectClass = 'choices__',
                ignore = false,
                i = 0,
                item = e.target.classList.contains('acc') ? e.target : e.target.closest('.acc'),
                itemHead = item.querySelector('.table-row');

            if (!e.target.classList.contains('table-row') && !itemHead.contains(e.target)) {
                ignore = true;
            }

            for (i; i < e.target.classList.length; i++) {
                if (e.target.classList[i].includes(selectClass)) {
                    ignore = true;
                    break;
                }
            }

            if (!ignore) {
                item.classList.toggle('is-open');
                this.openItems[id] = !this.openItems[id];
            }
        },
        deletePrint: function() {
          let _this = this;
          this.sending = true;
          axios.post( '/local/api/print/delete',
              {
                id: this.delete_id
              },
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.status=='OK') {
              _this.sending = false;
              _this.items[_this.delete_id].delete = true;
              _this.printDeleteOpen = false;
              _this.printDeleteSuccess = true;
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });


        },
        printEdit: function(id) {
            this.printEditCaps = this.items[id].caps;
            this.printEditId = id;
            this.printEditOpen = true;
        },
        send: function(e) {
            let form = e.target,
                formData = new FormData(),
                _this = this;

            this.error = [];
            formData.append('caps', JSON.stringify(_this.printEditCaps));
            formData.append('comment', _this.editComment);
            formData.append('id', _this.printEditId);

          this.sending = true;
          axios.post( '/local/api/print/edit',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.id) {
              _this.sending = false;
              _this.items[_this.printEditId].caps = response.data.caps;
              _this.printEditCaps = [];
              _this.printEditId = 0;
              _this.printEditOpen = false;
              _this.editComment = '';

            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });


          this.printEditOpen = false;

        },
      changeStatus(value, id) {
        // debugger;
        let _this = this;
        this.sending = true;
        axios.post( '/local/api/print/status',
            {
             id: id,
             status: value.detail.value
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(function(response){
          // debugger;
          if (response.data.id) {
            _this.sending = false;
            _this.items[id] = response.data.item;
          }
          else {
            _this.error_text = response.data.error;
            _this.sending = false;
          }
        })
            .catch(function(){
              _this.sending = false;
            });
      },
      deleteRequest(id) {
        this.printDeleteOpen = !this.printDeleteOpen;
        this.delete_id = id
      },
      addToRequest(type, key) {
        if (this.printEditCaps[type][key] != 'del')
          this.printEditCaps[type][key] = 'del'
        else
          this.printEditCaps[type][key] = this.items[this.printEditId].status;
      }
    },
}
</script>

