<template>
  <main class="main light_theme">
    <section class="section">
      <div class="site-container">
        <div class="auth-reg">
          <img class="auth-reg__logo" src="img/logo-preloader.svg" alt="Логотип ABC" width="211" height="56">
          <div class="info-block info-block--alert" v-if="error">
            <h3>Внимание</h3>
            <p><span v-html="error"></span></p>
          </div>
          <form @submit.prevent="auth" class="auth-reg__form form">
            <div class="form__input-block">
              <label class="form__input-label">
                E-mail<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input required v-model="login" autocomplete="off" class="form__input input" type="text" placeholder="Введите данные">
              </div>
            </div>

            <div class="form__input-block">
              <label class="form__input-label">
                Пароль<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input required v-model="password" class="form__input input" type="password" placeholder="Введите данные">
              </div>
            </div>

            <div class="auth-reg__links links-block">
              <button type="submit" class="links-block__link btn">Войти</button>
              <router-link to="/registration" class="links-block__link btn btn--non-target" href="#">Регистрация</router-link>
              <router-link to="/forget-password" class="links-block__link btn btn--non-target" href="#">Забыли пароль?</router-link>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";

export default {
  props: [

  ],
  data: () => ({
    login: '',
    password: '',
    error: ''
  }),
  created() {

  },
  mounted() {

    this.$store.dispatch('Loader');
  },
  beforeRouteLeave(to, from) {

    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
  methods: {
    auth() {
      this.error = '';
      let _this = this;
      axios.post('https://pivo.alm.su/local/api/login', {

          login: this.login,
          password: this.password,

      }).then(response => {
        let data = response.data;
        if (data.is_auth === true) {
          _this.$store.dispatch('Auth', {
            login: data.login,
            token: data.token,
            user: data.arUser,
            uuid: data.uuid,
            is_auth: data.is_auth
          })
          //_this.axios.defaults.params = {Authorization: data.token}
          //_this.axios.defaults.headers.common['Authorization'] = data.token;
          _this.$router.push('/');
        }
        else {
          _this.error = data.error.MESSAGE;
          console.log(data)
        }
      });

    }
  },
  components: {

  }
}
</script>

