<template>
  <div class="modal__title"><template v-if="id">Редактировать запись об оплате  #{{id}}</template><template v-else>Внести оплату</template></div>
    <form class="modal-form" action="#" method="post" @submit.prevent="send" ref="paymentForm" v-if="load === false">
        <div class="form-group" :class="[clinic?'is-filled':'']">
          <SimpleTypeahead
              id="typeahead_id"
              placeholder=""
              :items="itemClinics"
              :minInputLength="1"
              :defaultItem="clinic"
              @selectItem="selectItemEventHandler"
              class="form-control"
              v-model="clinic"
              :class="[!clinic && error.includes('clinic') ? 'form-control--error' : '']"
          >
          </SimpleTypeahead>
            <label class="form-label" for="">Клиника<span>*</span></label>
        </div>
        <div class="form-group" :class="[doctor?'is-filled':'']">
          <SimpleTypeahead
              id="typeahead_id"
              placeholder=""
              :items="itemDoctor"
              :minInputLength="1"
              @selectItem="selectDoctorEventHandler"
              class="form-control"
              v-model="doctor"
              :defaultItem="doctor"
              :disabled="itemDoctor.length==0"
              :class="[!doctor && error.includes('doctor') ? 'form-control--error' : '']"
          >
          </SimpleTypeahead>
            <label class="form-label" for="">Врач<span>*</span></label>
        </div>
        <div class="form-group" :class="[patient?'is-filled':'']">
          <SimpleTypeahead
              id="typeahead_id"
              placeholder=""
              :items="itemPatient"
              :minInputLength="1"
              @selectItem="selectPatientEventHandler"
              class="form-control"
              v-model="patient"
              :defaultItem="patient"
              :disabled="itemPatient.length==0"
              :class="[!patient && error.includes('patient') ? 'form-control--error' : '']"
          >
          </SimpleTypeahead>
            <label class="form-label" for="">Пациент<span>*</span></label>
        </div>
        <div class="form-row">
            <div class="form-group">
                <input class="form-control js-date-mask focus" :class="[error.includes('date') ? 'form-control--error' : '', date?'is-filled':'']" @input="isFilled" v-model="date" type="date" dv-mask="'##.##.####'">
                <label class="form-label" for="">Дата<span>*</span></label>
            </div>
            <div class="form-group form-group-ruble">
                <input class="form-control js-price-input" :class="[error.includes('sum') ? 'form-control--error' : '', sum?'is-filled':'']" @input="isFilled" v-model.number="sum" type="number" >
                <label class="form-label" for="">Сумма<span>*</span></label>
            </div>
        </div>
        <div v-if="error_text">
          <div v-for="er in error_text">{{er}}</div>
        </div>
        <div class="modal-action">
            <div class="btn js-modal-close" @click="close">Отменить</div>
            <button type="submit" class="btn btn--primary">Сохранить</button>
        </div>
    </form>
</template>

<script>
import {mask as maskDirective } from 'vue-the-mask';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import axios from "axios";
import Choices from "choices.js"; //Optional default CSS

export default {
    name: 'PaymentForm',
    directives: {
        mask: function (e, b) {
            if (!b.value) {
                return;
            }

            maskDirective(e, b);
        }
    },
  props: {
    cur_patient_id: '',
    cur_patient: '',
    cur_doctor: '',
    cur_clinic: '',
    cur_id: '',
    cur_sum: '',
    cur_date: ''
  },
    data: () => ({
        error: [],
        clinic: '',
        doctor: '',
        patient: '',
        date: '',
        sum: '',
      id: '',
      itemClinics: [
        'abc',
        'def'
      ],
      itemDoctor: [],
      doctors: {},
      itemPatient: [],
      patients: {},
      formdata: {},
      error_text: '',
      load: true,
      choices_clinic: '',
      choices_doctor: ''
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
  async created() {
    if (this.$props.cur_clinic)
      this.clinic = this.$props.cur_clinic;
    if (this.$props.cur_doctor)
      this.doctor = this.$props.cur_doctor;
    if (this.$props.cur_patient)
      this.patient = this.$props.cur_patient;
    if (this.$store.getters.role != 'doctor')
      await this.getData();

    if (this.$props.cur_id)
      this.id = this.$props.cur_id;
    if (this.$props.cur_sum)
      this.sum = this.$props.cur_sum;

    if (this.$props.cur_date && this.$props.cur_date.includes(".")) {
      let date = this.$props.cur_date.split(".");
      this.date = date[2] + '-'+date[1] + '-'+date[0];
    }
    else if (this.$props.cur_date)
      this.date = this.$props.cur_date;

    },
    computed: {

    },
    mounted() {

        //this.$store.dispatch('Loader');

    },
  updated() {
  /*    debugger;
    if (this.$props.cur_clinic)
      this.clinic = this.$props.cur_clinic;
    if (this.$props.cur_doctor)
      this.doctor = this.$props.cur_doctor;
    if (this.$props.cur_patient)
      this.patient = this.$props.cur_patient;

    if(this.clinic) {
      this.itemDoctor = this.doctors[this.clinic];
      if (this.doctor)
        if (this.patients[this.clinic][this.doctor])
          this.itemPatient = this.patients[this.clinic][this.doctor];
    }

    if (this.$props.cur_id)
      this.id = this.$props.cur_id;
    if (this.$props.cur_sum)
      this.sum = this.$props.cur_sum;
    if (this.$props.cur_date)
      this.date = this.$props.cur_date;*/
  },
  watch: {
      /*cur_clinic: function (newVal, oldVal) {
        debugger;
        this.clinic = newVal;
        this.itemDoctor = this.doctors[newVal];
      },
      cur_doctor: function (newVal, oldVal) {
        this.doctor = newVal;
        //this.itemPatient = this.patients[this.clinic][newVal];
      },
      cur_patient: function (newVal, oldVal) {
        this.patient = newVal;
      },
      cur_id: function (newVal, oldVal) {
        this.id = newVal;
      },
      cur_sum: function (newVal, oldVal) {
        this.sum = newVal;
      },*/
      /*cur_date: function (newVal, oldVal) {
        if (newVal.includes(".")) {
          let date = newVal.split(".");
          this.date = date[2] + '-'+date[1] + '-'+date[0];
        }
        else
          this.date = newVal;
      },*/
    },
    components: {SimpleTypeahead},
    methods: {
      async getData() {
        let _this = this;

        await axios.get('/local/api/payments/formdata').then(function (response) {
          _this.load = false;
          _this.formdata = response.data;
          _this.itemClinics = Object.keys(_this.formdata);
          for (let i in _this.formdata) {
            _this.doctors[i] = Object.keys(_this.formdata[i]);
            for (let ii in _this.formdata[i]) {
              if (typeof _this.patients[i] != 'object')
                _this.patients[i] = {};
              _this.patients[i][ii] = Object.keys(_this.formdata[i][ii]);
            }
          }
        }).catch(function(err) {

        });
      },
      selectItemEventHandler(value) {
        if(value) {
          this.itemDoctor = this.doctors[value];
          this.clinic = value;
        }
      },
      selectDoctorEventHandler(value) {
        if (value) {
          this.itemPatient = this.patients[this.clinic][value];
          this.doctor = value;
        }
      },
      selectPatientEventHandler(value) {
        this.patient = value;
      },
        isFilled: function(e) {
            let input;

            if (e instanceof Event) {
                input = e.target;
            } else {
                input = e;
            }

            if (input.value != '') {
                input.classList.add('is-filled')
            } else {
                input.classList.remove('is-filled')
            }
        },
        send: function(e) {
          if (this.sending === true)
            return;
            let form = e.target,
                inputs = form.querySelectorAll('input'),
                formData = new FormData(),
                _this = this;

            this.error = [];

            formData.append('clinic', _this.clinic);
            formData.append('doctor', _this.doctor);
            formData.append('patient', _this.patient);
            formData.append('date', _this.date);
            formData.append('sum', _this.sum);
          formData.append('id', _this.id);
            if (_this.formdata[_this.clinic] && _this.formdata[_this.clinic][_this.doctor])
            formData.append('patient_id', _this.formdata[_this.clinic][_this.doctor][_this.patient]);
            // console.log(formData);
          if (!this.clinic)
            this.error.push('clinic');
          if (!this.doctor)
            this.error.push('doctor');
          if (!this.patient)
            this.error.push('patient');
          if (!this.date)
            this.error.push('date');
          if (!this.sum)
            this.error.push('sum');

            /*for (const pair of formData.entries()) {
                if (pair[1] == '') {
                    this.error.push(pair[0]);
                }
            }

            inputs.forEach(function(input) {
                _this.isFilled(input);
            });*/

            if (this.error.length == 0) {
              this.sending = true;
              axios.post( '/local/api/payments/add',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
              ).then(function(response){
                if (response.data.id) {
                  _this.sending = false;
                  _this.success();
                }
                else {
                  _this.error_text = response.data.error;
                  _this.sending = false;
                }
              })
                  .catch(function(){
                    _this.sending = false;
                  });

            }
        },
        close: function() {
            this.$emit('close', true);
        },
        success: function() {
            let filledInputs = this.$refs.paymentForm.querySelectorAll('.is-filled');
            filledInputs.forEach(function(input) {
                input.classList.remove('is-filled');
            });

            this.error = [];
            this.clinic = '';
            this.doctor = '';
            this.patient = '';
            this.date = '';
            this.sum = '';

            this.$emit('success', true);
        },
    },
    emits: ['close', 'success'],
}
</script>
