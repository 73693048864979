<template>
    <DeptsGroup v-if="role=='admin' || role=='buhgalter'"></DeptsGroup>
    <DeptsDoctor v-if="role=='doctor'"></DeptsDoctor>
</template>

<script>
import DeptsGroup from '@/views/DeptsGroup';
import DeptsDoctor from "@/views/DeptsDoctor"

export default {
    data: () => ({

    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {

    },
    components: {
      DeptsGroup, DeptsDoctor
    },
    methods: {},
}
</script>

