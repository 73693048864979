<template>
    <component :is="layout" v-if="isLoad">
      <router-view/>
    </component>
    <div class="preloader preloader--loading" v-if="!loader"></div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'
import axios from 'axios'
export default {
  data: () => ({
    myvar: 'test',
    device_uuid: '',
    device_token: '',
    isLoad: false,
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    loader() {
     // return this.$store.getters.loader;
    },
    mytoken() {
      return this.$attrs.mytoken
    },
  },
  components: {
    EmptyLayout, MainLayout
  },
  async created() {

    // проверить авторизацию
    // если нет - редирект на форму входа
    // если да - получить данные пользователя, список доступных страниц и прав

    //let device_uuid = this.$attrs.device_uuid;
    //this.device_uuid = device_uuid;
    //this.$store.dispatch('Uuid', device_uuid)
    let _this = this;
    await axios.get('/local/api/auth').then(function (response) {
      let data = response.data;
      // debugger;
      if (data.is_auth === false) {
        _this.$router.push('/auth');
       // window.location.href = '/main-not-auth';
      }
      _this.$store.dispatch('Auth', data)

      _this.axios.interceptors.response.use(function (response) {
        if (response.data.auth == false)
          _this.$router.push('/auth');
        // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
        // Здесь можете сделать что-нибудь с ответом
        return response;
      }, function (error) {
        if (error.response.status == 401) {
          _this.$router.push('/auth');
          //window.location.href = '/main-not-auth';
        }
        if (error.response.status == 500) {
          _this.$router.push('/404');
          //window.location.href = '/main-not-auth';
        }
        // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
        // Здесь можете сделать что-то с ошибкой ответа
        return Promise.reject(error);
      });
    }).catch(function(){

    });

    this.isLoad = true;
  }
}
</script>