<template>
  <template v-if="id">
    <h1 class="title">Заявка на нового пациента #{{ id }}</h1>
    <div class="info">
        <div class="info__item"> <span>Дата создания заявки</span>{{ date }}</div>
        <div class="info__item"> <span>ФИО пациента</span>{{ patient }}</div>
        <div class="info__item"> <span>Клиника</span>{{ clinic }}</div>
        <div class="info__item"> <span>Врач</span>{{ doctor }}</div>
    </div>
    <div class="section" v-if="klkt">
        <div class="section__title">КЛКТ челюсти</div>
        <div class="docs">
            <a target="_blank" :href="item.link" class="doc" v-for="item in klkt">
                <div class="doc__name">{{ item.filename }}</div>
                <div class="doc__size">{{ item.size }}</div>
            </a>
        </div>
    </div>
    <div class="section" v-if="scans">
        <div class="section__title">Сканы (.stl)</div>
        <div class="docs">
            <a target="_blank" :href="scan.link" class="doc" v-for="scan in scans">
                <div class="doc__name">{{ scan.filename }}</div>
                <div class="doc__size">{{ scan.size }}</div>
            </a>
        </div>
    </div>
    <div class="section" v-if="photos">
        <div class="section__title">Фотографии пациента
            <div class="photos">
                <div class="photos__item" v-for="(photo, key) in photos">
                    <img :src="photo.link" alt="">
                    <div class="photos__item-action">
                        <div class="photos__item-show show js-open-modal" data-modal="gallery" @click="modalPhoto(photos, key)">
                            <svg width="30" height="30">
                                <use xlink:href="@/images/sprite.svg#eye"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section" v-if="comment.length">
        <div class="section__title">Комментарий от врача</div>
        <div class="comment">{{ comment }}</div>
    </div>
    <div v-if="role == 'admin' && type=='new'" class="action">
        <div class="btn btn--big btn--primary btn--icon" @click="createCard"><span>Создать карточку</span>
            <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
            </svg>
        </div>
        <div class="btn btn--big" @click="showStatusModal = !showStatusModal">Отклонить заявку</div>
    </div>

    <div class="modal" :class="showSlide ? 'is-open' : ''" data-modal="gallery" v-if="modalPhotos.length">
        <div class="modal-overlay" @click="showSlide = !showSlide"> </div>
        <div class="modal-wrapper">
            <div class="modal-content modal-content--xl">
                <div class="modal-close" @click="showSlide = !showSlide">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">{{ modalPhotos[currentModalPhoto].date }}</div>
                <div class="modal-gallery">
                    <div class="modal-gallery__slider">
                        <div class="swiper-wrapper">
                            <swiper
                                :initial-slide="currentModalPhoto"
                                :modules="modules"
                                :navigation="{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                }"
                                :loop="false"
                                :autoplay="false"
                                :space-between="0"
                                :slides-per-view="1"
                                @swiper="setSwiperObj"
                                @slideChange="onSlideChange"
                            >
                                <swiper-slide v-for="photo in modalPhotos">
                                    <div class="modal-gallery__item swiper-slide">
                                        <div class="modal-gallery__photo"><img :src="photo.link" alt=""></div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                    <div class="modal-gallery__prev slider-prev swiper-button-prev" ref="prev">
                        <svg width="7" height="14">
                            <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                        </svg>
                    </div>
                    <div class="modal-gallery__next slider-next swiper-button-next" ref="next">
                        <svg width="7" height="14">
                            <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                        </svg>
                    </div>
                </div>
                <div class="modal-gallery__text">{{ modalPhotos[currentModalPhoto].comment }}</div>
            </div>
        </div>
    </div>
    <div class="modal" :class="showStatusModal ? 'is-open' : ''">
        <div class="modal-overlay" @click="showStatusModal = !showStatusModal"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="showStatusModal = !showStatusModal">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Отклонить заявку? Заявка будет перемещена в список обработанных.</div>
                <div class="modal-form">
                    <div class="modal-action">
                        <div class="btn" @click="changeStatus('decline')">Да</div>
                        <div class="btn btn--primary" @click="showStatusModal = !showStatusModal">Нет</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" :class="showErrorModal ? 'is-open' : ''">
      <div class="modal-overlay" @click="showErrorModal = !showErrorModal"></div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close" @click="showErrorModal = !showErrorModal">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div class="modal__title">{{ error_text }}</div>
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="load">
    загрузка...
  </template>
  <template v-else>
    <Notfound></Notfound>
  </template>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import {Navigation} from 'swiper';
import axios from 'axios';
import Notfound from '@/views/Notfound';

export default {
    data: () => ({
      load: true,
        isOpen: false,
        id: '',
        date: '',
        patient: '',
        clinic: '',
        doctor: '',
        klkt: {},
        scans: {},
        photos: {},
        comment: '',

        modules: [Navigation],
        swiperObj: null,
        showSlide: false,
        currentModalPhoto: 0,
        modalPhotos: [],
        type: '',
        showStatusModal: false,
      showErrorModal: false,
        error_text: ''
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {
      this.$store.commit('breadcrumb_page', '');
    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {
        let _this = this;
        this.type = this.$route.params.type;
        if (this.$route.params.type != 'new' && this.$route.params.type != 'archive') {
          _this.load = false;
        }
        else
        await axios.get('/local/api/patient/request/detail/'+this.$route.params.type+'/'+this.$route.params.id).then(function (response) {
          _this.load = false;
            _this.id = response.data.id;
            _this.date = response.data.date;
            _this.patient = response.data.patient;
            _this.clinic = response.data.clinic;
            _this.doctor = response.data.doctor;
            _this.klkt = response.data.klkt;
            _this.scans = response.data.scans;
            _this.photos = response.data.photos;
            _this.comment = response.data.comment;
            _this.$store.commit('breadcrumb_page', 'Заявка #'+response.data.id);
        }).catch(function(err) {
          _this.$router.push('/404');
        });
    },
    components: {
        Swiper,
        SwiperSlide,
        Notfound
    },
    methods: {
        setSwiperObj: function(swiper) {
            this.swiperObj = swiper;
        },
        modalPhoto: function(data, id) {
            this.modalPhotos = data;
            this.currentModalPhoto = id;
            if (this.swiperObj != null)
                this.swiperObj.slideTo(id);
            this.showSlide = true;
        },
        onSlideChange: function(swiper) {
            this.currentModalPhoto = swiper.realIndex;
        },
        changeStatus: function(status) {
            if (this.sending === true)
                return;

            let formData = new FormData(),
                _this = this;

            formData.append('status', status);

            // if (this.error.length == 0) {
                this.sending = true;
                axios.post(
                    '/local/api/patient/changestatus/'+this.$route.params.id,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(response){
                    if (response.data.id) {
                        console.log('ok');
                        if (status == 'decline') {
                          _this.$router.push('/');
                        }
                    }
                    else {
                      _this.showErrorModal = true;
                      _this.showStatusModal = false;
                        _this.error_text = response.data.error;
                        _this.sending = false;
                    }
                })
                .catch(function(){
                    _this.sending = false;
                });
            // }
        },
        createCard: function() {
        if (this.sending === true)
          return;

        let formData = new FormData(),
            _this = this;


        // if (this.error.length == 0) {
        this.sending = true;
        axios.post(
            '/local/api/patient/create/'+this.$route.params.id,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(function(response){
          if (response.data.id) {
            console.log('ok');
            if (response.data.status == 'ok') {
              _this.$router.push('/patient/'+_this.$route.params.id);
            }
          }
          else {
            _this.showErrorModal = true;
            _this.error_text = response.data.error;
            _this.sending = false;
          }
        })
            .catch(function(){
              _this.sending = false;
            });
        // }
      },
    },
}
</script>

