<template>
    <PatientGroup v-if="role == 'admin' || role == 'tehnik'"></PatientGroup>
    <DoctorPatients v-if="role == 'doctor'"></DoctorPatients>
</template>

<script>

import axios from "axios";
import PatientGroup from "@/views/PatientsGroup"
import DoctorPatients from "@/views/DoctorPatients"

export default {
    data: () => ({
        items: {},
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {

    },
    components: {PatientGroup, DoctorPatients},
    methods: {

    },
}
</script>