<template>
  <template v-if="id">
    <h1 class="title">Заявка на изменение плана лечения #{{ id }}</h1>
    <div class="info">
        <div class="info__item"><span>Дата создания заявки</span>{{ date }}</div>
        <div class="info__item info__item--light"><span>ФИО пациента</span><router-link :to="'/patient/'+patient_id">{{ patient }}</router-link>
        </div>
        <div class="info__item"><span>Клиника</span>{{ clinic }}</div>
        <div class="info__item"><span>Врач</span>{{ doctor }}</div>
    </div>
    <div class="section" v-if="comment.length">
        <div class="section__title">Комментарий от врача</div>
        <div class="comment">{{ comment }}</div>
    </div>
    <div class="section" v-if="files.length">
        <div class="section__title">Файлы</div>
        <div class="docs">
            <a class="doc" :href="file.link" target="_blank" v-for="file in files">
                <div class="doc__name">{{ file.filename }}</div>
                <div class="doc__size">{{ file.size }}</div>
            </a>
        </div>
    </div>
    <div v-if="role == 'admin' && type=='new'" class="action">
        <div class="btn btn--big btn--primary btn--icon" @click="showStatusModal = !showStatusModal"><span>Отметить как обработанную</span></div>
    </div>

    <div class="modal" :class="showStatusModal ? 'is-open' : ''">
        <div class="modal-overlay" @click="showStatusModal = !showStatusModal"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="showStatusModal = !showStatusModal">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Закрыть заявку? Заявка будет перемещена в список обработанных.</div>
                <div class="modal-form">
                    <div class="modal-action">
                        <div class="btn" @click="changeStatus('closed')">Да</div>
                        <div class="btn btn--primary" @click="showStatusModal = !showStatusModal">Нет</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  <template v-else-if="load">
    загрузка...
  </template>
  <template v-else>
    <Notfound></Notfound>
  </template>
</template>

<script>

import axios from "axios";
import Notfound from '@/views/Notfound';

export default {
    data: () => ({
      isOpen: false,
      id: '',
      date: '',
      patient: '',
      patient_id: 0,
      clinic: '',
      doctor: '',
      comment: '',
      files: {},
      type: '',
      load: true,
      showStatusModal: false,
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {
        let _this = this;
      this.type = this.$route.params.type;
      if (this.$route.params.type != 'new' && this.$route.params.type != 'archive') {
        _this.load = false;
      }
      else
        await axios.get('/local/api/plan/request/detail/'+this.$route.params.type+'/'+this.$route.params.id).then(function (response) {
            _this.id = response.data.id;
            _this.date = response.data.date;
            _this.patient = response.data.patient;
          _this.patient_id = response.data.patient_id;
            _this.clinic = response.data.clinic;
            _this.doctor = response.data.doctor;
            _this.comment = response.data.comment;
            _this.files = response.data.files;
          _this.$store.commit('breadcrumb_page', 'Заявка #'+response.data.id);

        }).catch(function(err) {
          _this.$router.push('/404');
        });
    },
    components: {Notfound},
    methods: {
        changeStatus: function(status) {
            if (this.sending === true)
                return;

            let formData = new FormData(),
                _this = this;

            formData.append('status', status);

            // if (this.error.length == 0) {
                this.sending = true;
                axios.post(
                    '/local/api/plan/changestatus/'+this.$route.params.id,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(response){
                    if (response.data.id) {
                        console.log('ok');
                      _this.$router.push('/');
                    }
                    else {
                        _this.error_text = response.data.error;
                        _this.sending = false;
                    }
                })
                .catch(function(){
                    _this.sending = false;
                });
            // }
        },
    },
}
</script>

