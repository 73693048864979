<template>
  <!-- SVG start -->
  <svg class="main_svg" style="display: none" xmlns="http://www.w3.org/2000/svg">
    <symbol id="received_ic" viewBox="0 0 20 20" fill="none" width="20" height="20">
      <g clip-path="url(#clip0_1633_20053)">
        <path d="M10.0001 12.5001C7.23866 12.5001 5.00008 10.2615 5.00008 7.50008V2.87045C5.00008 2.52558 5.00008 2.35314 5.05034 2.21506C5.13459 1.98359 5.31693 1.80125 5.5484 1.717C5.68648 1.66675 5.85891 1.66675 6.20379 1.66675H13.7964C14.1413 1.66675 14.3137 1.66675 14.4518 1.717C14.6832 1.80125 14.8656 1.98359 14.9498 2.21506C15.0001 2.35314 15.0001 2.52558 15.0001 2.87045V7.50008C15.0001 10.2615 12.7615 12.5001 10.0001 12.5001ZM10.0001 12.5001V15.0001M15.0001 3.33341H17.0834C17.4717 3.33341 17.6658 3.33341 17.819 3.39685C18.0232 3.48143 18.1854 3.64366 18.27 3.84785C18.3334 4.00099 18.3334 4.19513 18.3334 4.58341V5.00008C18.3334 5.77506 18.3334 6.16255 18.2482 6.48046C18.0171 7.34319 17.3432 8.01706 16.4805 8.24823C16.1625 8.33342 15.7751 8.33342 15.0001 8.33342M5.00008 3.33341H2.91675C2.52846 3.33341 2.33432 3.33341 2.18118 3.39685C1.97699 3.48143 1.81476 3.64366 1.73018 3.84785C1.66675 4.00099 1.66675 4.19513 1.66675 4.58341V5.00008C1.66675 5.77506 1.66675 6.16255 1.75193 6.48046C1.9831 7.34319 2.65697 8.01706 3.5197 8.24823C3.83762 8.33342 4.22511 8.33342 5.00008 8.33342M6.20379 18.3334H13.7964C14.0009 18.3334 14.1667 18.1676 14.1667 17.963C14.1667 16.3266 12.8402 15.0001 11.2038 15.0001H8.79638C7.15998 15.0001 5.83341 16.3266 5.83341 17.963C5.83341 18.1676 5.99924 18.3334 6.20379 18.3334Z" stroke="url(#paint0_linear_1633_20053)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_1633_20053" x1="2.85722" y1="1.66675" x2="18.3334" y2="19.5239" gradientUnits="userSpaceOnUse">
          <stop stop-color="#B39766"/>
          <stop offset="0.35" stop-color="#D8B379"/>
          <stop offset="0.48" stop-color="#EFDCBD"/>
          <stop offset="0.61" stop-color="#D8B379"/>
          <stop offset="1" stop-color="#C0A16C"/>
        </linearGradient>
        <clipPath id="clip0_1633_20053">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </symbol>
    <symbol id="received_ic_l" viewBox="0 0 20 20" fill="none" width="20" height="20">
      <g clip-path="url(#clip0_1929_4180)">
        <path d="M9.99984 12.5001C7.23841 12.5001 4.99984 10.2615 4.99984 7.50008V2.87045C4.99984 2.52558 4.99984 2.35314 5.05009 2.21506C5.13434 1.98359 5.31668 1.80125 5.54815 1.717C5.68623 1.66675 5.85867 1.66675 6.20354 1.66675H13.7961C14.141 1.66675 14.3134 1.66675 14.4515 1.717C14.683 1.80125 14.8653 1.98359 14.9496 2.21506C14.9998 2.35314 14.9998 2.52558 14.9998 2.87045V7.50008C14.9998 10.2615 12.7613 12.5001 9.99984 12.5001ZM9.99984 12.5001V15.0001M14.9998 3.33341H17.0832C17.4715 3.33341 17.6656 3.33341 17.8187 3.39685C18.0229 3.48143 18.1852 3.64366 18.2697 3.84785C18.3332 4.00099 18.3332 4.19513 18.3332 4.58341V5.00008C18.3332 5.77506 18.3332 6.16255 18.248 6.48046C18.0168 7.34319 17.3429 8.01706 16.4802 8.24823C16.1623 8.33342 15.7748 8.33342 14.9998 8.33342M4.99984 3.33341H2.9165C2.52822 3.33341 2.33408 3.33341 2.18093 3.39685C1.97674 3.48143 1.81452 3.64366 1.72994 3.84785C1.6665 4.00099 1.6665 4.19513 1.6665 4.58341V5.00008C1.6665 5.77506 1.6665 6.16255 1.75169 6.48046C1.98286 7.34319 2.65673 8.01706 3.51946 8.24823C3.83737 8.33342 4.22486 8.33342 4.99984 8.33342M6.20354 18.3334H13.7961C14.0007 18.3334 14.1665 18.1676 14.1665 17.963C14.1665 16.3266 12.8399 15.0001 11.2035 15.0001H8.79613C7.15973 15.0001 5.83317 16.3266 5.83317 17.963C5.83317 18.1676 5.99899 18.3334 6.20354 18.3334Z" stroke="#FBFBFB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1929_4180">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </symbol>
    <symbol id="star" width="48" height="45" fill="none" viewBox="0 0 48 45">
      <path d="M24 0.5L30.9412 15.4463L47.3009 17.4291L35.231 28.6492L38.4007 44.8209L24 36.809L9.59926 44.8209L12.769 28.6492L0.699116 17.4291L17.0588 15.4463L24 0.5Z" fill="#11231B"/>
      <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" stroke="white" stroke-opacity="0.2" stroke-width="2"/>
    </symbol>
    <symbol id="star_l" width="48" height="45" viewBox="0 0 48 45" fill="none">
      <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" fill="#F3F5F9" stroke="#BBB6B6" stroke-width="2"/>
    </symbol>
    <symbol id="star_active" width="48" height="45" fill="none" viewBox="0 0 48 45">
      <path d="M24 0.5L30.9412 15.4463L47.3009 17.4291L35.231 28.6492L38.4007 44.8209L24 36.809L9.59926 44.8209L12.769 28.6492L0.699116 17.4291L17.0588 15.4463L24 0.5Z" fill="#697671"/>
      <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" stroke="white" stroke-opacity="0.2" stroke-width="2"/>
    </symbol>
    <symbol id="star_active_l" width="48" height="45" fill="none" viewBox="0 0 48 45">
      <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" fill="#BBB6B6" stroke="#F3F5F9" stroke-width="2"/>
    </symbol>
    <symbol id="lock_ic" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.25 6.25H13.75V4.375C13.75 3.38044 13.3549 2.42661 12.6517 1.72335C11.9484 1.02009 10.9946 0.625 10 0.625C9.00544 0.625 8.05161 1.02009 7.34835 1.72335C6.64509 2.42661 6.25 3.38044 6.25 4.375V6.25H3.75C3.41848 6.25 3.10054 6.3817 2.86612 6.61612C2.6317 6.85054 2.5 7.16848 2.5 7.5V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V7.5C17.5 7.16848 17.3683 6.85054 17.1339 6.61612C16.8995 6.3817 16.5815 6.25 16.25 6.25ZM7.5 4.375C7.5 3.71196 7.76339 3.07607 8.23223 2.60723C8.70107 2.13839 9.33696 1.875 10 1.875C10.663 1.875 11.2989 2.13839 11.7678 2.60723C12.2366 3.07607 12.5 3.71196 12.5 4.375V6.25H7.5V4.375ZM16.25 16.25H3.75V7.5H16.25V16.25ZM10.9375 11.875C10.9375 12.0604 10.8825 12.2417 10.7795 12.3958C10.6765 12.55 10.5301 12.6702 10.3588 12.7411C10.1875 12.8121 9.99896 12.8307 9.8171 12.7945C9.63525 12.7583 9.4682 12.669 9.33709 12.5379C9.20598 12.4068 9.11669 12.2398 9.08051 12.0579C9.04434 11.876 9.06291 11.6875 9.13386 11.5162C9.20482 11.3449 9.32498 11.1985 9.47915 11.0955C9.63332 10.9925 9.81458 10.9375 10 10.9375C10.2486 10.9375 10.4871 11.0363 10.6629 11.2121C10.8387 11.3879 10.9375 11.6264 10.9375 11.875Z" fill="#9698A5"/>
    </symbol>
    <symbol id="lock_ic_l" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.25 6.25H13.75V4.375C13.75 3.38044 13.3549 2.42661 12.6517 1.72335C11.9484 1.02009 10.9946 0.625 10 0.625C9.00544 0.625 8.05161 1.02009 7.34835 1.72335C6.64509 2.42661 6.25 3.38044 6.25 4.375V6.25H3.75C3.41848 6.25 3.10054 6.3817 2.86612 6.61612C2.6317 6.85054 2.5 7.16848 2.5 7.5V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V7.5C17.5 7.16848 17.3683 6.85054 17.1339 6.61612C16.8995 6.3817 16.5815 6.25 16.25 6.25ZM7.5 4.375C7.5 3.71196 7.76339 3.07607 8.23223 2.60723C8.70107 2.13839 9.33696 1.875 10 1.875C10.663 1.875 11.2989 2.13839 11.7678 2.60723C12.2366 3.07607 12.5 3.71196 12.5 4.375V6.25H7.5V4.375ZM16.25 16.25H3.75V7.5H16.25V16.25ZM10.9375 11.875C10.9375 12.0604 10.8825 12.2417 10.7795 12.3958C10.6765 12.55 10.5301 12.6702 10.3588 12.7411C10.1875 12.8121 9.99896 12.8307 9.8171 12.7945C9.63525 12.7583 9.4682 12.669 9.33709 12.5379C9.20598 12.4068 9.11669 12.2398 9.08051 12.0579C9.04434 11.876 9.06291 11.6875 9.13386 11.5162C9.20482 11.3449 9.32498 11.1985 9.47915 11.0955C9.63332 10.9925 9.81458 10.9375 10 10.9375C10.2486 10.9375 10.4871 11.0363 10.6629 11.2121C10.8387 11.3879 10.9375 11.6264 10.9375 11.875Z" fill="#A172A2"/>
    </symbol>
    <symbol id="user_ic" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M13.8334 2.8898C15.0682 3.50343 15.9167 4.77762 15.9167 6.25C15.9167 7.72238 15.0682 8.99657 13.8334 9.6102M15.5001 13.972C16.7596 14.5419 17.8938 15.4708 18.8334 16.6667M2.16675 16.6667C3.78882 14.6021 5.99106 13.3333 8.41675 13.3333C10.8424 13.3333 13.0447 14.6021 14.6667 16.6667M12.1667 6.25C12.1667 8.32107 10.4878 10 8.41675 10C6.34568 10 4.66675 8.32107 4.66675 6.25C4.66675 4.17893 6.34568 2.5 8.41675 2.5C10.4878 2.5 12.1667 4.17893 12.1667 6.25Z" stroke="white" stroke-opacity="0.2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol id="user_ic_l" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M13.8332 2.8898C15.0679 3.50343 15.9165 4.77762 15.9165 6.25C15.9165 7.72238 15.0679 8.99657 13.8332 9.6102M15.4998 13.972C16.7594 14.5419 17.8936 15.4708 18.8332 16.6667M2.1665 16.6667C3.78858 14.6021 5.99082 13.3333 8.4165 13.3333C10.8422 13.3333 13.0444 14.6021 14.6665 16.6667M12.1665 6.25C12.1665 8.32107 10.4876 10 8.4165 10C6.34544 10 4.6665 8.32107 4.6665 6.25C4.6665 4.17893 6.34544 2.5 8.4165 2.5C10.4876 2.5 12.1665 4.17893 12.1665 6.25Z" stroke="#BBB6B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol id="award_ic" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_3102_109)">
        <path d="M9.99996 12.5C7.23854 12.5 4.99996 10.2614 4.99996 7.49999V2.87036C4.99996 2.52549 4.99996 2.35305 5.05022 2.21497C5.13446 1.9835 5.3168 1.80116 5.54828 1.71691C5.68635 1.66666 5.85879 1.66666 6.20366 1.66666H13.7963C14.1411 1.66666 14.3136 1.66666 14.4516 1.71691C14.6831 1.80116 14.8655 1.9835 14.9497 2.21497C15 2.35305 15 2.52549 15 2.87036V7.49999C15 10.2614 12.7614 12.5 9.99996 12.5ZM9.99996 12.5V15M15 3.33332H17.0833C17.4716 3.33332 17.6657 3.33332 17.8189 3.39676C18.0231 3.48134 18.1853 3.64356 18.2699 3.84775C18.3333 4.0009 18.3333 4.19504 18.3333 4.58332V4.99999C18.3333 5.77497 18.3333 6.16245 18.2481 6.48037C18.0169 7.3431 17.3431 8.01697 16.4803 8.24814C16.1624 8.33332 15.7749 8.33332 15 8.33332M4.99996 3.33332H2.91663C2.52834 3.33332 2.3342 3.33332 2.18106 3.39676C1.97687 3.48134 1.81464 3.64356 1.73006 3.84775C1.66663 4.0009 1.66663 4.19504 1.66663 4.58332V4.99999C1.66663 5.77497 1.66663 6.16245 1.75181 6.48037C1.98298 7.3431 2.65685 8.01697 3.51958 8.24814C3.83749 8.33332 4.22498 8.33332 4.99996 8.33332M6.20366 18.3333H13.7963C14.0008 18.3333 14.1666 18.1675 14.1666 17.963C14.1666 16.3266 12.8401 15 11.2037 15H8.79626C7.15986 15 5.83329 16.3266 5.83329 17.963C5.83329 18.1675 5.99911 18.3333 6.20366 18.3333Z" stroke="white" stroke-opacity="0.2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_3102_109">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </symbol>
    <symbol id="super_star" height="45" viewBox="0 0 48 45" fill="none">
      <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" fill="#697671"/>
      <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" fill="url(#paint0_linear_1633_19907)"/>
      <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" stroke="#D2D6D4" stroke-width="2"/>
      <defs>
        <linearGradient id="paint0_linear_1633_19907" x1="3" y1="0.500001" x2="48.5" y2="53" gradientUnits="userSpaceOnUse">
          <stop stop-color="#B39766"/>
          <stop offset="0.35" stop-color="#D8B379"/>
          <stop offset="0.48" stop-color="#EFDCBD"/>
          <stop offset="0.61" stop-color="#D8B379"/>
          <stop offset="1" stop-color="#C0A16C"/>
        </linearGradient>
      </defs>
    </symbol>
    <symbol id="close_x" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path d="M21 9L9 21M9 9L21 21" stroke="white" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round"/>
    </symbol>
    <symbol id="close_x_l" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path d="M13 1L1 13M1 1L13 13" stroke="#C2C2C2" stroke-width="1.5" stroke-linecap="round"/>
    </symbol>
    <symbol id="refresh_ic" width="31" height="30" viewBox="0 0 31 30" fill="none">
      <path d="M7.42243 18.0524C8.74959 20.6332 11.4391 22.3986 14.5409 22.3986C17.6427 22.3986 20.3322 20.6332 21.6594 18.0524M7.42243 18.0524L6.97857 22.0023M7.42243 18.0524L11.3529 18.978M22.5038 13.6292C22.1164 9.57241 18.6992 6.39966 14.5409 6.39966C10.3827 6.39966 6.96544 9.57241 6.578 13.6292M22.5038 13.6292L19.2915 12.0655M22.5038 13.6292L24.4219 10.7694" stroke="#21D28B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol id="refresh_ic_l" width="23" height="20" viewBox="0 0 23 20" fill="none">
      <path d="M3.42243 13.0524C4.74959 15.6332 7.43909 17.3986 10.5409 17.3986C13.6427 17.3986 16.3322 15.6332 17.6594 13.0524M3.42243 13.0524L2.97857 17.0023M3.42243 13.0524L7.3529 13.978M18.5038 8.62915C18.1164 4.57241 14.6992 1.39966 10.5409 1.39966C6.38266 1.39966 2.96544 4.57241 2.578 8.62915M18.5038 8.62915L15.2915 7.06553M18.5038 8.62915L20.4219 5.76944" stroke="#292929" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </symbol>
    <symbol id="award_ic_l" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_3111_1277)">
        <path d="M9.99996 12.5001C7.23854 12.5001 4.99996 10.2615 4.99996 7.50008V2.87045C4.99996 2.52558 4.99996 2.35314 5.05022 2.21506C5.13446 1.98359 5.3168 1.80125 5.54828 1.717C5.68635 1.66675 5.85879 1.66675 6.20366 1.66675H13.7963C14.1411 1.66675 14.3136 1.66675 14.4516 1.717C14.6831 1.80125 14.8655 1.98359 14.9497 2.21506C15 2.35314 15 2.52558 15 2.87045V7.50008C15 10.2615 12.7614 12.5001 9.99996 12.5001ZM9.99996 12.5001V15.0001M15 3.33341H17.0833C17.4716 3.33341 17.6657 3.33341 17.8189 3.39685C18.0231 3.48143 18.1853 3.64366 18.2699 3.84785C18.3333 4.00099 18.3333 4.19513 18.3333 4.58341V5.00008C18.3333 5.77506 18.3333 6.16255 18.2481 6.48046C18.0169 7.34319 17.3431 8.01706 16.4803 8.24823C16.1624 8.33342 15.7749 8.33342 15 8.33342M4.99996 3.33341H2.91663C2.52834 3.33341 2.3342 3.33341 2.18106 3.39685C1.97687 3.48143 1.81464 3.64366 1.73006 3.84785C1.66663 4.00099 1.66663 4.19513 1.66663 4.58341V5.00008C1.66663 5.77506 1.66663 6.16255 1.75181 6.48046C1.98298 7.34319 2.65685 8.01706 3.51958 8.24823C3.83749 8.33342 4.22498 8.33342 4.99996 8.33342M6.20366 18.3334H13.7963C14.0008 18.3334 14.1666 18.1676 14.1666 17.963C14.1666 16.3266 12.8401 15.0001 11.2037 15.0001H8.79626C7.15986 15.0001 5.83329 16.3266 5.83329 17.963C5.83329 18.1676 5.99911 18.3334 6.20366 18.3334Z" stroke="#BBB6B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_3111_1277">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </symbol>
    <symbol id="search_iconn" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="11" cy="11" r="7" stroke="white" stroke-opacity="0.2" stroke-width="1.5"/>
      <path d="M20 20L17 17" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round"/>
    </symbol>
    <symbol id="search_iconn_l" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="11" cy="11" r="7" stroke="#C2C2C2" stroke-width="1.5"/>
      <path d="M20 20L17 17" stroke="#C2C2C2" stroke-width="1.5" stroke-linecap="round"/>
    </symbol>
  </svg>
  <!-- SVG end -->
    <h1 class="title">Достижения</h1>
    <section class="progress">

      <div class="progress_block">
        <div class="section_subtitle">Успейте достичь целей до конца периода!</div>

        <div class="progress_top">
          <div class="level_left">
            <p>{{ progress.level }}</p>
            <span>уровень</span>
          </div>
          <div class="level_right">
            <div class="level_r_text">
              <div class="level_r_text_sp">{{progress.month}}</div>
              <p>Осталось дней до конца периода: <span class="day_sp">{{progress.days}}</span></p>
            </div>

            <div class="progress_bar lite_bar">
              <div class="progress-value" :data-progress="progress.count" :style="'--progress:'+progress.count/progress.point2*100+'%'"></div>
              <div class="star_ic star_one" :class="progress.count >= progress.point1?' active':''" :style="'left: calc('+progress.point1/progress.point2*100+'% - 35px);'">
                <svg width="48" height="45" class="star_i">
                  <path d="M24 0.5L30.9412 15.4463L47.3009 17.4291L35.231 28.6492L38.4007 44.8209L24 36.809L9.59926 44.8209L12.769 28.6492L0.699116 17.4291L17.0588 15.4463L24 0.5Z" fill="#11231B"/>
                  <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" stroke="white" stroke-opacity="0.2" stroke-width="2"/>
                </svg>
                <svg width="48" height="45" class="star_i_l" :class="progress.count >=progress.point1?' active':''">
                  <use xlink:href="#star_l" />
                </svg>
                <div class="star_num">{{ progress.point1 }} пациентов</div>
              </div>
              <div class="star_ic star_two" :class="progress.count >=progress.point2?' active':''">
                <svg width="48" height="45" class="star_i" >
                  <path d="M24 0.5L30.9412 15.4463L47.3009 17.4291L35.231 28.6492L38.4007 44.8209L24 36.809L9.59926 44.8209L12.769 28.6492L0.699116 17.4291L17.0588 15.4463L24 0.5Z" fill="#11231B"/>
                  <path d="M24 2.87416L30.0342 15.8675L30.2685 16.3721L30.8208 16.4391L45.0429 18.1627L34.5502 27.9168L34.1427 28.2956L34.2497 28.8415L37.0052 42.9002L24.4862 35.9351L24 35.6646L23.5138 35.9351L10.9948 42.9002L13.7503 28.8415L13.8573 28.2956L13.4498 27.9168L2.95708 18.1627L17.1792 16.4391L17.7315 16.3721L17.9658 15.8675L24 2.87416Z" stroke="white" stroke-opacity="0.2" stroke-width="2"/>
                </svg>
                <svg width="48" height="45" class="star_i_l" :class="progress.count >=progress.point2?' active':''">
                  <use xlink:href="#star_l" />
                </svg>
                <div class="star_num">{{ progress.point2 }} пациентов</div>
              </div>
            </div>
            <div class="level_r_text">
              <div class="level_r_text_sp">Создано пациентов: <span class="clients_num_sp">{{ progress.count }}</span></div>
              <p v-if="progress.need > 0">Еще <span class="again_num_sp">{{ progress.need }}</span> до достижения новой цели</p>
            </div>
          </div>
        </div>

        <div class="received active" v-if="progress.award">
          <div class="received_title">Получена награда!</div>
          <div class="received_box">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="received_ic">
              <g clip-path="url(#clip0_1582_12581)">
                <path d="M9.99999 12.4993C7.23857 12.4993 4.99999 10.2608 4.99999 7.49935V2.86972C4.99999 2.52485 4.99999 2.35241 5.05025 2.21433C5.1345 1.98286 5.31683 1.80052 5.54831 1.71627C5.68638 1.66602 5.85882 1.66602 6.20369 1.66602H13.7963C14.1412 1.66602 14.3136 1.66602 14.4517 1.71627C14.6831 1.80052 14.8655 1.98286 14.9497 2.21433C15 2.35241 15 2.52485 15 2.86972V7.49935C15 10.2608 12.7614 12.4993 9.99999 12.4993ZM9.99999 12.4993V14.9993M15 3.33268H17.0833C17.4716 3.33268 17.6658 3.33268 17.8189 3.39612C18.0231 3.48069 18.1853 3.64292 18.2699 3.84711C18.3333 4.00026 18.3333 4.1944 18.3333 4.58268V4.99935C18.3333 5.77433 18.3333 6.16181 18.2481 6.47973C18.017 7.34246 17.3431 8.01633 16.4804 8.2475C16.1625 8.33268 15.775 8.33268 15 8.33268M4.99999 3.33268H2.91666C2.52837 3.33268 2.33423 3.33268 2.18109 3.39612C1.9769 3.48069 1.81467 3.64292 1.73009 3.84711C1.66666 4.00026 1.66666 4.1944 1.66666 4.58268V4.99935C1.66666 5.77433 1.66666 6.16181 1.75184 6.47973C1.98301 7.34246 2.65688 8.01633 3.51961 8.2475C3.83753 8.33268 4.22501 8.33268 4.99999 8.33268M6.20369 18.3327H13.7963C14.0008 18.3327 14.1667 18.1669 14.1667 17.9623C14.1667 16.3259 12.8401 14.9993 11.2037 14.9993H8.79629C7.15989 14.9993 5.83332 16.3259 5.83332 17.9623C5.83332 18.1669 5.99914 18.3327 6.20369 18.3327Z" stroke="url(#paint0_linear_1582_12581)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <linearGradient id="paint0_linear_1582_12581" x1="2.85713" y1="1.66602" x2="18.3333" y2="19.5232" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#B39766"/>
                  <stop offset="0.35" stop-color="#D8B379"/>
                  <stop offset="0.48" stop-color="#EFDCBD"/>
                  <stop offset="0.61" stop-color="#D8B379"/>
                  <stop offset="1" stop-color="#C0A16C"/>
                </linearGradient>
                <clipPath id="clip0_1582_12581">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <svg width="20" height="20" class="received_ic_l">
              <use xlink:href="#received_ic_l" />
            </svg>
            <p>{{ progress.award }}</p>
          </div>
          <div class="received_mativation_text" v-if="progress.accept < progress.point2">
            Продолжайте достигать цели!
          </div>
        </div>

        <div class="next_period">
          <div class="next_period_top">
            <div class="next_period_title">Уровень на следующий период</div>
            <ul class="next_period_ul">
              <li v-for="item in level">
                <a @click="setLevel(item.id)" :class="item.next_current?'active':''" class="modal_opener_base">{{ item.name }}
                <svg v-if="item.lock" width="20" height="20" class="lockk_ic">
                  <use xlink:href="#lock_ic" />
                </svg>
                <svg width="20" height="20" class="lockk_ic_l">
                  <use xlink:href="#lock_ic_l" />
                </svg>
              </a>
              </li>
            </ul>
          </div>
          <div class="next_period_desc">
            Вы можете выбрать другой доступный уровень на следующий период или остаться на старом. <br> Выбрать уровень можно до конца текущего периода. Новые уровни становятся доступными при <br> достижении хотя бы одной цели предыдущего уровня.
          </div>
        </div>

        <div class="rules">
          <div class="rules_title">Правила</div>
          <div class="rules_table_block">
            <table class="rules_table">
              <thead>
              <tr>
                <th>Уровень</th>
                <th>Пациентов</th>
                <th>Период</th>
                <th>Награда</th>
              </tr>
              </thead>
              <tbody>
              <tr :class="item.level.toLowerCase()" v-for="item in rules">
                <td>
                  <div class="first_td">
                    <span></span> <p>{{ item.level }}</p>
                  </div>
                </td>
                <td>
                  <div class="user_td">
                    <svg width="21" height="20" class="userr_ic">
                      <use xlink:href="#user_ic" />
                    </svg>
                    <svg width="21" height="20" class="userr_ic_l">
                      <use xlink:href="#user_ic_l" />
                    </svg>
                    <span>{{ item.patient }}</span>
                  </div>
                </td>
                <td>{{ item.period }}</td>
                <td>
                  <div class="td_last">
                    <svg width="20" height="20" class="awardd_ic">
                      <use xlink:href="#award_ic" />
                    </svg>
                    <svg width="20" height="20" class="awardd_ic_l">
                      <use xlink:href="#award_ic_l" />
                    </svg>

                    <p>{{ item.award }}</p>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

  </section>

  <section class="modals">
    <div class="container">
      <div class="modals_block">


        <!-- The LITE modal and overlay -->
        <div class="award_modal modal_lite">
          <div class="modal_header">
            Отличная работа!
          </div>
          <div class="modal_body">
            <p>До получения награды осталось пациентов: <span>1</span> </p>
            <div class="ok_btn">Ok</div>
          </div>
          <div id="closeModalBtnLite" class="closeModalBtn">
            <svg width="30" height="30" class="closee_x">
              <use xlink:href="#close_x" />
            </svg>
            <svg width="14" height="14" class="closee_x_l">
              <use xlink:href="#close_x_l" />
            </svg>
          </div>
        </div>
        <div id="overlayLite" class="overlay"></div>


        <!-- The BASE modal and overlay -->
        <div class="award_modal modal_base">
          <div class="modal_header">
            Отличная работа!
          </div>
          <div class="modal_body">
            <p>До получения награды осталось пациентов: <span>1</span> </p>
            <div class="ok_btn">Ok</div>
          </div>
          <div id="closeModalBtnBase" class="closeModalBtn">
            <svg width="30" height="30" class="closee_x">
              <use xlink:href="#close_x" />
            </svg>
            <svg width="14" height="14" class="closee_x_l">
              <use xlink:href="#close_x_l" />
            </svg>

          </div>
        </div>
        <div id="overlayBase" class="overlay"></div>


        <!-- The SUPER modal and overlay -->
        <div class="award_modal modal_super">
          <div class="modal_header">
            Отличная работа!
          </div>
          <div class="modal_body">
            <div class="ok_btn">Ok</div>
          </div>
          <div id="closeModalBtnSuper" class="closeModalBtn">
            <svg width="30" height="30" class="closee_x">
              <use xlink:href="#close_x" />
            </svg>
            <svg width="14" height="14" class="closee_x_l">
              <use xlink:href="#close_x_l" />
            </svg>
          </div>
        </div>
        <div id="overlaySuper" class="overlay"></div>

      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import '../css/awards.css'

export default {
    data: () => ({
      rules: {},
      progress: {},
      level: {}
    }),
    created() {

    },
    computed: {

    },
    async mounted() {
      this.get();
    },
    components: {

    },
    methods: {
      async get() {
        let _this = this;
        await axios.get('/local/api/progress').then(function (response) {
          _this.rules = response.data.rules;
          _this.level = response.data.level;
          _this.progress = response.data.item;
        }).catch(function(err) {
          //_this.$router.push('/404');
        });
      },
        setLevel: function(id) {
          let _this = this;
          this.sending = true;
          axios.post( '/local/api/level',
              {
                level: id
              },
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(function(response){
            if (response.data.success=='OK') {
              _this.sending = false;
              for (let i in _this.level) {
                if (_this.level[i].id == id)
                  _this.level[i].next_current = true;
                else
                  _this.level[i].next_current = false;
              }
            }
            else {
              _this.error_text = response.data.error;
              _this.sending = false;
            }
          })
              .catch(function(){
                _this.sending = false;
              });


        },
    },
}
</script>

