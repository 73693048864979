<template>
    <h1 class="title">Заявки</h1>
  <RequestMenu v-if="role=='admin'"></RequestMenu>
  <div v-if="role=='doctor'" class="row">
    <DoctorRequestMenu></DoctorRequestMenu>
    <router-link to="/patientnew" class="btn btn--big btn--icon"><span>Новый пациент</span>
      <svg width="30" height="30">
        <use xlink:href="images/sprite.svg#plus-big"></use>
      </svg>
    </router-link>
  </div>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table">
                    <div class="table-head">
                        <div class="table-cell">Номер</div>
                        <div class="table-cell table-cell--center">Дата</div>
                        <div class="table-cell">Клиника</div>
                        <div class="table-cell">Врач</div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'/order/archive/'+item.id" v-for="(item, id) in items">
                        <div class="table-cell">#{{ item.id }}</div>
                        <div class="table-cell table-cell--light">{{ item.date }}</div>
                        <div class="table-cell table-cell--light">{{ item.clinic }}</div>
                        <div class="table-cell table-cell--light">{{ item.patient }}</div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <Pagination v-if="page.num_page > 1" @gotopage="gotopage" :pagination="page"></Pagination>
</template>

<script>

import axios from "axios";
import RequestMenu from "@/components/app/RequestMenu"
import Pagination from "@/components/app/Pagination"
import DoctorRequestMenu from "@/components/app/DoctorRequestMenu"

export default {
    data: () => ({
        isOpen: false,
        items: {},
        pagination: {}
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      page() {
        return this.pagination;
      },
      role() {
        return this.$store.getters.role;
      }
    },
    mounted() {
      this.gotopage(1);
    },
    components: {RequestMenu, Pagination, DoctorRequestMenu},
    methods: {
      async gotopage(page, add=false) {
        let _this = this;

        await axios.get('/local/api/patient/request/archive?page='+page).then(function (response) {
          if (add == true)
            _this.items = _this.merge(_this.items, response.data.items);
          else
            _this.items = response.data.items;
          _this.pagination = response.data.pagination;
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      },
      merge(arr1, arr2) {
        let item, items = [];
        for (item in arr1) {
          items.push(arr1[item]);
        }
        for (item in arr2) {
          items.push(arr2[item]);
        }
        return items;
      }
    },
}
</script>

