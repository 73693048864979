<template>
    <template v-if="role != 'buhgalter'">
    <h1 class="title">Заявки</h1>
    <RequestMenu v-if="role=='admin'"></RequestMenu>
    <div v-if="role=='doctor'" class="row">
      <DoctorRequestMenu></DoctorRequestMenu>
      <router-link to="/patientnew" class="btn btn--big btn--icon"><span>Новый пациент</span>
        <svg width="30" height="30">
          <use xlink:href="@/images/sprite.svg#plus-big"></use>
        </svg>
      </router-link>
    </div>
    <div class="card is-new">
        <div class="card-head">
            <h2 class="card-title">Новые пациенты<span>{{ newItemsSize }}</span></h2>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <div class="table">
                    <div class="table-head">
                        <div class="table-cell">Номер</div>
                        <div class="table-cell table-cell--center">Дата</div>
                        <div v-if="false && role!='doctor'" class="table-cell">Клиника</div>
                        <div v-if="role!='doctor'" class="table-cell">Врач</div>
                        <div class="table-cell">Пациент</div>
                        <div v-if="role=='doctor'" class="table-cell"></div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'/order/new/'+id" v-for="(item, id) in newItems">
                        <div class="table-cell">#{{ id }}</div>
                        <div class="table-cell table-cell--center table-cell--light">{{ item.date }}</div>
                        <div v-if="false && role!='doctor'" class="table-cell table-cell--light">{{ item.clinic }}</div>
                        <div v-if="role!='doctor'" class="table-cell table-cell--light">{{ item.doctor }}</div>
                        <div class="table-cell table-cell--light">{{ item.patient }}</div>
                      <div v-if="role=='doctor'" class="table-cell"></div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="card is-new">
        <div class="card-head">
            <h2 class="card-title">Печать<span>{{ printItemsSize }}</span></h2>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <div class="table">
                    <div class="table-head">
                        <div class="table-cell">Номер</div>
                        <div class="table-cell table-cell--center">Дата</div>
                        <div v-if="role!='doctor'" class="table-cell">Врач</div>
                        <div class="table-cell">Пациент</div>
                      <div v-if="role=='doctor'" class="table-cell"></div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'/order-print/new/'+item.id" v-for="(item, id) in printItems">
                        <div class="table-cell">#{{ item.id }}</div>
                        <div class="table-cell table-cell--light">{{ item.date }}</div>
                        <div v-if="role!='doctor'" class="table-cell table-cell--light">{{ item.clinic }}</div>
                        <div class="table-cell table-cell--light">{{ item.patient }}</div>
                      <div v-if="role=='doctor'" class="table-cell"></div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="card is-new">
        <div class="card-head">
            <h2 class="card-title">Изменение плана лечения<span>{{ changeItemsSize }}</span></h2>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <div class="table">
                    <div class="table-head">
                        <div class="table-cell">Номер</div>
                        <div class="table-cell table-cell--center">Дата</div>
                        <div v-if="role!='doctor'" class="table-cell">Врач</div>

                        <div class="table-cell">Пациент</div>
                      <div v-if="role=='doctor'" class="table-cell"></div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'/order-change/new/'+id" v-for="(item, id) in changeItems">
                        <div class="table-cell">#{{ id }}</div>
                        <div class="table-cell table-cell--light">{{ item.date }}</div>
                        <div v-if="role!='doctor'" class="table-cell table-cell--light">{{ item.clinic }}</div>
                        <div class="table-cell table-cell--light">{{ item.patient }}</div>
                        <div v-if="role=='doctor'" class="table-cell table-cell--light"></div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    </template>
</template>

<script>
import axios from 'axios';
import Choices from 'choices.js';
import RequestMenu from "@/components/app/RequestMenu"
import DoctorRequestMenu from "@/components/app/DoctorRequestMenu"

export default {
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {
        //this.$store.dispatch('Loader');
        let selectList = document.querySelectorAll('.js-select');

        selectList.forEach(function (item) {
            let choices = new Choices(item);
        });

        let _this = this;

        axios.all([
            axios.get('/local/api/patient/request/new'),
            axios.get('/local/api/print/request/new'),
            axios.get('/local/api/plan/request/new')
        ])
            .then(axios.spread((patientResponse, printResponse, planResponse) => {
                _this.newItems = patientResponse.data.items;
                _this.printItems = printResponse.data.items;
                _this.changeItems = planResponse.data.items;

              this.newItemsSize = Object.keys(patientResponse.data.items).length;
              this.printItemsSize = Object.keys(printResponse.data.items).length;
              this.changeItemsSize = Object.keys(planResponse.data.items).length;
            }))
            .catch(error => console.log(error));
    },
    components: {RequestMenu, DoctorRequestMenu},
    methods: {},
    data: () => ({
        newItems: {},
        newItemsSize: 0,
        printItems: {},
        printItemsSize: 0,
        changeItems: {},
        changeItemsSize: 0,
        allItemsSize: 0
    }),
}
</script>

