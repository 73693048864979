<template>
  <div class="tabs">
    <router-link class="tabs-item" active-class="is-active" to="/patients">Группировка по клиникам</router-link>
    <router-link class="tabs-item" active-class="is-active" to="/patients-all"> Показать все<span>{{ all }}</span></router-link>
  </div>
</template>

<script>
export default {
    data: () => ({

    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {},
    computed: {
      all() {
        return this.$store.getters.patientNumbers?this.$store.getters.patientNumbers:'';
      }
    },
    async mounted() {
      if (!this.$store.getters.patientNumbers)
        await this.$store.dispatch('fetchPatientNumbers')
    },
    components: {},
    methods: {}
}
</script>
