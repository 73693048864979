<template>
  <div class="tabs">
    <router-link class="tabs-item" active-class="is-active" to="/print">Неготовые<span>{{ work }}</span></router-link>
    <router-link class="tabs-item" active-class="is-active" to="/print-ready">Готовые<span>{{ ready }}</span></router-link>
  </div>
</template>

<script>
export default {
    data: () => ({

    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {},
    computed: {
      work() {
        return this.$store.getters.printNumbers?this.$store.getters.printNumbers.work:'';
      },
      ready() {
        return this.$store.getters.printNumbers?this.$store.getters.printNumbers.ready:'';
      }
    },
    async mounted() {
      if (!this.$store.getters.printNumbers)
        await this.$store.dispatch('fetchPrintNumbers')
    },
    components: {},
    methods: {}
}
</script>
