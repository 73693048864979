<template>
  <template v-if="id">
    <h1 class="title">Заявка на печать #{{ id }}</h1>
    <div class="info">
        <div class="info__item"><span>Дата создания заявки</span>{{ date }}</div>
        <div class="info__item info__item--light"><span>ФИО пациента</span><router-link :to="'/patient/'+patient_id">{{ patient }}</router-link>
        </div>
        <div class="info__item"><span>Клиника</span>{{ clinic }}</div>
        <div class="info__item"><span>Врач</span>{{ doctor }}</div>
    </div>
    <div class="section">
        <div class="mouthguards">
            <div class="mouthguards-line">
                <div class="mouthguards__symbol">
                    <svg width="16" height="16">
                        <use xlink:href="@/images/sprite.svg#plus"></use>
                    </svg>
                </div>
                <div class="mouthguards-list">
                    <div class="mouthguards__item" :class="'is-'+status" v-for="(status, id) in caps.plus">{{ id }}</div>
                </div>
            </div>
            <div class="mouthguards-line">
                <div class="mouthguards__symbol">
                    <svg width="16" height="16">
                        <use xlink:href="@/images/sprite.svg#minus"></use>
                    </svg>
                </div>
                <div class="mouthguards-list">
                    <div class="mouthguards__item" :class="'is-'+status" v-for="(status, id) in caps.minus">{{ id }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="section" v-if="comment.length">
        <div class="section__title">Комментарий от врача</div>
        <div class="comment">{{ comment }}</div>
    </div>
    <div v-if="role == 'admin' && type=='new'" class="action">
        <div class="btn btn--big btn--primary btn--icon" @click="showAddPrint = true"><span>Добавить в очередь</span>
            <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
            </svg>
        </div>
        <div class="btn btn--big" @click="showStatusModal = !showStatusModal">Отклонить заявку</div>
    </div>
    <div class="modal" :class="showStatusModal ? 'is-open' : ''">
        <div class="modal-overlay" @click="showStatusModal = !showStatusModal"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="showStatusModal = !showStatusModal">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Отклонить заявку? Заявка будет перемещена в список обработанных.</div>
                <div class="modal-form">
                    <div class="modal-action">
                        <div class="btn" @click="changeStatus('decline')">Да</div>
                        <div class="btn btn--primary" @click="showStatusModal = !showStatusModal">Нет</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" :class="showErrorModal ? 'is-open' : ''">
      <div class="modal-overlay" @click="showErrorModal = !showErrorModal"></div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close" @click="showErrorModal = !showErrorModal">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div class="modal__title">{{ error_text }}</div>
        </div>
      </div>
    </div>

    <div class="modal" :class="showAddPrint ? 'is-open' : ''" data-modal="print-edit">
      <div class="modal-overlay"> </div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close" @click="showAddPrint=false">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div class="modal__title">Добавить в очередь на печать</div>
          <div class="modal__desc">Если какие-то каппы не нужно печатать - отметьте их серым</div>
          <div class="mouthguards">
            <div v-if="Object.keys(caps.plus).length > 0" class="mouthguards-line">
              <div class="mouthguards__symbol">
                <svg width="16" height="16">
                  <use xlink:href="@/images/sprite.svg#plus"></use>
                </svg>
              </div>
              <div class="mouthguards-list">
                <div class="mouthguards__item" :class="getCapRequestStatus(id, 'plus',status)" v-for="(status, id) in caps.plus" @click="addToRequest(id, 'plus', status)">{{ id }}</div>
              </div>
            </div>
            <div v-if="Object.keys(caps.minus).length > 0" class="mouthguards-line">
              <div class="mouthguards__symbol">
                <svg width="16" height="16">
                  <use xlink:href="@/images/sprite.svg#minus"></use>
                </svg>
              </div>
              <div class="mouthguards-list">
                <div class="mouthguards__item" :class="getCapRequestStatus(id, 'minus',status)" v-for="(status, id) in caps.minus" @click="addToRequest(id, 'minus', status)">{{ id }}</div>
              </div>
            </div>
          </div>
          <form class="modal-form modal-form--comments" @submit.prevent="addPrint">
            <br>
            <div class="form-group">
              <input v-model="printComment" class="form-control" type="text" placeholder="Комментарий">
            </div>
            <div class="modal-action">
              <div class="btn js-modal-close" @click="showAddPrint = !showAddPrint">Отменить</div>
              <button type="submit" class="btn btn--primary js-modal-close">Добавить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="load">
    загрузка...
  </template>
  <template v-else>
    <Notfound></Notfound>
  </template>
</template>

<script>

import axios from "axios";
import Notfound from '@/views/Notfound';

export default {
    data: () => ({
      isOpen: false,
      id: '',
      date: '',
      patient: '',
      patient_id: '',
      clinic: '',
      doctor: '',
      caps: {},
      comment: '',
      type: '',
      load: true,
      showStatusModal: false,
      showErrorModal: false,
      showAddPrint: false,
      error_text: '',
      printComment: '',
      capRequest: []
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    async mounted() {
        let _this = this;
        this.type = this.$route.params.type;
        if (this.$route.params.type != 'new' && this.$route.params.type != 'archive') {
          _this.load = false;
        }
        else
        await axios.get('/local/api/print/request/detail/'+this.$route.params.type+'/'+this.$route.params.id).then(function (response) {
          _this.load = false;
          _this.id = response.data.id;
          _this.date = response.data.date;
          _this.patient = response.data.patient;
          _this.patient_id = response.data.patient_id;
          _this.clinic = response.data.clinic;
          _this.doctor = response.data.doctor;
          _this.comment = response.data.comment;
          _this.caps = response.data.caps;
          _this.capRequest = response.data.caps;
          _this.$store.commit('breadcrumb_page', 'Заявка #'+response.data.id);
        }).catch(function(err) {
          _this.$router.push('/404');
        });
    },
    components: {Notfound},
    methods: {
        changeStatus: function(status) {
            if (this.sending === true)
                return;

            let formData = new FormData(),
                _this = this;

            formData.append('status', status);

            // if (this.error.length == 0) {
                this.sending = true;
                axios.post(
                    '/local/api/print/changestatus/'+this.$route.params.id,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(function(response){
                    if (response.data.id) {
                        console.log('ok');
                        if (status == 'decline') {
                          _this.$router.push('/');
                        }
                    }
                    else {
                      _this.showErrorModal = true;
                      _this.showStatusModal = false;
                        _this.error_text = response.data.error;
                        _this.sending = false;
                    }
                })
                .catch(function(){
                    _this.sending = false;
                });
            // }
        },
      addPrint: function() {
        if (this.sending === true)
          return;

        let formData = new FormData(),
            _this = this;

        formData.append('comment', _this.printComment);
        formData.append('plus', JSON.stringify(_this.capRequest.plus));
        formData.append('minus', JSON.stringify(_this.capRequest.minus));

        // if (this.error.length == 0) {
        this.sending = true;
        axios.post(
            '/local/api/print/create/'+this.$route.params.id,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(function(response){
          if (response.data.id) {
            console.log('ok');
            if (response.data.status == 'ok') {
              _this.$router.push('/print');
            }
          }
          else {
            _this.showErrorModal = true;
            _this.error_text = response.data.error;
            _this.sending = false;
          }
        })
            .catch(function(){
              _this.sending = false;
            });
        // }
      },
      getCapRequestStatus: function(id, type, status) {
        status = this.capRequest[type][id];
        return 'is-'+status;
      },
      addToRequest: function(id, type, status) {
        if (true || !status) {
          if (this.capRequest[type][id] == '')
            this.capRequest[type][id] = 'new';
          else
            this.capRequest[type][id] = ''
        }
      },
    },
}
</script>

