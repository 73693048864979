<template>
    <router-view />
</template>

<script>

export default {
  name: 'empty-layout',
  props: [
    'title'
  ],
  data: () => ({

  }),
  methods: {
    prevPage() {
      this.$router.go(-1)
    }
  },
  components: {

  }
}
</script>