<template>
    <div class="modal__title">Редактировать сумму к оплате</div>
    <form class="modal-form" action="#" method="post" @submit.prevent="send" ref="paymentForm" >
        <div class="form-row">
            <div class="form-group form-group-ruble">
                <input class="form-control js-price-input is-filled" :class="error.includes('sum') ? 'form-control--error' : ''" @input="isFilled" v-model.number="sum" type="number">
                <label class="form-label" for="">Сумма<span>*</span></label>
            </div>
        </div>
        <div v-if="error_text">
          <div v-for="er in error_text">{{er}}</div>
        </div>
        <div class="modal-action">
            <div class="btn js-modal-close" @click="close">Отменить</div>
            <button type="submit" class="btn btn--primary">Сохранить</button>
        </div>
    </form>
</template>

<script>
import {mask as maskDirective } from 'vue-the-mask';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import axios from "axios"; //Optional default CSS

export default {
    name: 'SumForm',
    directives: {
        mask: function (e, b) {
            if (!b.value) {
                return;
            }

            maskDirective(e, b);
        }
    },
  props: {
    cur_patient_id: '',
    cur_sum: ''
  },
    data: () => ({
        error: [],
        sum: '',
      patient_id: '',
      formdata: {},
      error_text: '',
      load: true
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
  async created() {
    if (this.$props.cur_sum)
      this.sum = this.$props.cur_sum;
    if (this.$props.cur_patient_id)
      this.patient_id = this.$props.cur_patient_id;


    },
    computed: {

    },
    mounted() {


        //this.$store.dispatch('Loader');

    },
    components: {SimpleTypeahead},
    methods: {
        isFilled: function(e) {
            let input;

            if (e instanceof Event) {
                input = e.target;
            } else {
                input = e;
            }

            if (input.value != '') {
                input.classList.add('is-filled')
            } else {
                input.classList.remove('is-filled')
            }
        },
        send: function(e) {
          debugger;
          if (this.sending === true)
            return;
            let form = e.target,
                inputs = form.querySelectorAll('input'),
                formData = new FormData(),
                _this = this;

            this.error = [];

            formData.append('sum', _this.sum);
            formData.append('patient_id', _this.patient_id);

          if (!this.patient_id)
            this.error.push('patient_id');
          if (!this.sum)
            this.error.push('sum');


            if (this.error.length == 0) {
              this.sending = true;
              axios.post( '/local/api/payments/sum',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
              ).then(function(response){
                if (response.data.id) {
                  _this.sending = false;
                  _this.success();
                }
                else {
                  _this.error_text = response.data.error;
                  _this.sending = false;
                }
              })
                  .catch(function(){
                    _this.sending = false;
                  });

            }
        },
        close: function() {
            this.$emit('close', true);
        },
        success: function() {
            let filledInputs = this.$refs.paymentForm.querySelectorAll('.is-filled');
            filledInputs.forEach(function(input) {
                input.classList.remove('is-filled');
            });

            this.error = [];
            this.clinic = '';
            this.doctor = '';
            this.patient = '';
            this.date = '';
            this.sum = '';

            this.$emit('success', true);
        },
    },
    emits: ['close', 'success'],
}
</script>
