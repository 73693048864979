<template>
    <h1 class="title">{{clinic}}</h1>
    <SalesLiderMenu></SalesLiderMenu>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-patient">
                    <div class="table-head">
                        <div class="table-cell">Врач</div>
                        <div class="table-cell table-cell--center">Пациенты</div>
                        <div class="table-cell"></div>
                    </div>
                    <div class="table-row" v-for="(item, id) in items">
                        <div class="table-celll table-cell--light">
                          <svg v-if="item.num < 3" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0L12.8331 6.10054L19.5106 6.90983L14.5841 11.4895L15.8779 18.0902L10 14.82L4.12215 18.0902L5.41591 11.4895L0.489435 6.90983L7.16687 6.10054L10 0Z" :fill="color[item.num]"/>
                          </svg>
                          {{item.name}}</div>
                        <div class="table-cell table-cell--center">{{item.patients}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import SalesLiderMenu from "@/components/app/SalesLiderMenu";

export default {
    data: () => ({
      clinic: '',
      items: {},
      color: [
        '#FDC604',
        '#B1B1B1',
        '#A15116'
      ]
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {
      this.$store.commit('breadcrumb_page', '');
    },
    computed: {

    },
    async mounted() {
      let _this = this;

      await axios.get('/local/api/saleslider?clinic='+this.$route.params.id).then(function (response) {
        _this.clinic = response.data.clinic;
        _this.items = response.data.items;
        _this.$store.commit('breadcrumb_page', response.data.clinic);

      }).catch(function(err) {
        _this.$router.push('/404');
      });
    },
    components: {SalesLiderMenu},
    methods: {

    },
}
</script>