<template>
    <Print></Print>
</template>

<script>
import Print from "@/views/Print"

export default {
    data() {
        return {

        }
    },
    components: {
      Print
    }
}
</script>

