<template>
  <template v-if="patient">
    <h1 class="title">{{ patient }}</h1>
    <div class="row">
        <div class="dept-price">
            <div class="dept-price__title">Долг</div>
            <div class="dept-price__num">{{ sumCurrent }} ₽<span>{{ sumTotal }} ₽</span></div>
            <div class="dept-price__edit js-open-modal" data-modal="edit-summ" @click="isOpenSum=true">
                <svg width="30" height="30">
                    <use xlink:href="@/images/sprite.svg#edit"></use>
                </svg>
            </div>
        </div>
        <div v-if="role=='buhgalter' || role=='admin'" class="btn btn--big btn--primary btn--icon js-open-modal" data-modal="add-payment" @click="isOpen=true">
            <span>Внести оплату</span>
            <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
            </svg>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-payment">
                    <div class="table-head">
                        <div class="table-cell">Номер</div>
                        <div class="table-cell table-cell--center">Дата</div>
                        <div class="table-cell table-cell--center">Сумма</div>
                        <div class="table-cell">Пациент</div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="'order'+id" v-for="(item, id) in items">
                        <div class="table-cell">#{{ item.id }}</div>
                        <div class="table-cell table-cell--center">{{ item.date }}</div>
                        <div class="table-cell table-cell--right">{{ item.sum }} ₽</div>
                        <div class="table-cell table-cell--light">{{ patient }}</div>
                        <div class="table-cell">
                          <div class="table-cell__action">
                            <div class="edit js-open-modal" data-modal="edit-payment" @click.prevent="edit_id = item.id; edit_sum=item.sum; edit_date = item.date; isOpen=true">
                              <svg width="30" height="30">
                                <use xlink:href="@/images/sprite.svg#edit"></use>
                              </svg>
                            </div>
                            <div class="remove js-open-modal" data-modal="remove-payment" @click.prevent="delete_id = item.id; isDelete=true">
                              <svg width="30" height="30">
                                <use xlink:href="@/images/sprite.svg#remove"></use>
                              </svg>
                            </div>
                          </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" :class="isOpen?'is-open':''" data-modal="add-payment">
        <div class="modal-overlay" @click="isOpen=false;  edit_id = 0; edit_sum=''; edit_date = '';"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="isOpen=false;  edit_id = 0; edit_sum=''; edit_date = '';">
                    <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                    </svg>
                </div>
                <PaymentForm :key="edit_id?edit_id:'new'" @close="isOpen = false; edit_id = 0; edit_sum=''; edit_date = ''; " @success="get();isOpen = false; isSuccess = true; successText = 'Оплата успешно внесена!'" :cur_clinic="clinic" :cur_doctor="doctor" :cur_patient="patient" :cur_patient_id="$route.params.id" :cur_id="edit_id"  :cur_sum="edit_sum"  :cur_date="edit_date"></PaymentForm>
            </div>
        </div>
    </div>

    <div class="modal" :class="isOpenSum?'is-open':''" data-modal="add-payment">
      <div class="modal-overlay" @click="isOpenSum=false"></div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close" @click="isOpenSum=false">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <SumForm @close="isOpenSum = false" @success="get();isOpenSum = false; isSuccess = true; successText = 'Сумма к оплате успешно изменена'" :cur_sum="sumTotal" :cur_patient_id="$route.params.id"></SumForm>
        </div>
      </div>
    </div>

    <div class="modal" :class="isSuccess?'is-open':''" data-modal="3">
        <div class="modal-overlay" @click="isSuccess = false"> </div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close">
                    <svg width="30" height="30">
                        <use xlink:href="images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">{{successText}}</div>
                <div class="modal-action">
                    <div class="btn btn--primary js-modal-close" @click="isSuccess = false">Ок</div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" :class="isDelete ? 'is-open' : ''" data-modal="print-delete">
      <div class="modal-overlay" @click="isDelete = false"></div>
      <div class="modal-wrapper">
        <div class="modal-content">
          <div class="modal-close" @click="isDelete = false">
            <svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#close"></use>
            </svg>
          </div>
          <div class="modal__title">Удалить оплату?
            <div class="modal-action">
              <div class="btn js-modal-close" @click="deletePayment">Да</div>
              <div class="btn btn--primary js-modal-close" @click="isDelete = false; delete_id=0">Нет
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </template>
  <template v-else-if="load">
    загрузка...
  </template>
  <template v-else>
    <Notfound></Notfound>
  </template>
</template>

<script>
import PaymentForm from '../components/app/PaymentForm';
import SumForm from '../components/app/SumForm';
import axios from "axios";
import Notfound from '@/views/Notfound';

export default {
    data: () => ({
        isOpen: false,
      isOpenSum: false,
        isSuccess: false,
      successText: '',
        patient: '',
        sumCurrent: '',
        sumTotal: '',
        items: {},
        load: true,
      doctor:'',
      clinic: '',
      isDelete: false,
      delete_id: 0,
      edit_id: 0,
      edit_sum: '',
      edit_date: '',
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {
      this.$store.commit('breadcrumb_page', '');
    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    mounted() {
        this.get();
    },
    components: {
        PaymentForm, Notfound, SumForm
    },
    methods: {
      async get() {
        let _this = this;

        await axios.get('/local/api/payments/patient/'+this.$route.params.id).then(function (response) {
          _this.load = false;
          _this.patient = response.data.patient;
          _this.doctor = response.data.doctor;
          _this.clinic = response.data.clinic;
          _this.sumCurrent = response.data.sumCurrent;
          _this.sumTotal = response.data.sumTotal;

          _this.items = response.data.items
          _this.$store.commit('breadcrumb_page', response.data.patient);
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      },
      editPayment(id) {

      },
      deletePayment() {
        let _this = this;
        let url = '/local/api/payments/delete/'+this.delete_id;
        axios.get(url).then(function (response) {
          _this.get();
          _this.isDelete = false;
          _this.delete_id=0;
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      }
    },
}
</script>

