import axios from 'axios'
import Vuex from 'vuex'
import breadcrumbs from '@/store/breadcrumbs'

export default new Vuex.Store({
    state: {
        error: null,
        isAuth: false, //localStorage.getItem('user-token')?true:false,
        user: [],
        user_id: '',
        role: '',
        mainpage: '/',
        config: {
            headers:{
                token: localStorage.getItem('user-token')
            }
        },
        requestNumbers: false,
        printNumbers: false,
        patientNumbers: false,
        patientNumbersAwards: false,
        deptNumbers: false
    },
    mutations: {
        Auth(state, data) {
            state.isAuth = data.is_auth;
            state.user = data.user;
            state.user_id = data.user_id;
            state.role = data.role;
            state.mainpage = data.mainpage;
        },
        Loader(state, data) {
            state.loader = true;
        },
        unLoader(state, data) {
            state.loader = false;
        },
        setError(state, error) {
            state.error = error
        },
        clearError(state) {
            state.error = null
        },
        Logout(state, data) {
            state.isAuth = false;
            //localStorage.setItem('user-token', '');
            //state.login = data.login
        },
        requestNumbers(state, requestNumbers) {
            state.requestNumbers = requestNumbers;
        },
        printNumbers(state, printNumbers) {
            state.printNumbers = printNumbers;
        },
        patientNumbers(state, patientNumbers) {
            state.patientNumbers = patientNumbers;
        },
        patientNumbersAwards(state, patientNumbersAwards) {
            state.patientNumbersAwards = patientNumbersAwards;
        },
        deptNumbers(state, deptNumbers) {
            state.deptNumbers = deptNumbers;
        }
    },
    actions: {
        async Auth({commit, dispatch}, data) {
            commit('Auth', data);
        },
        Loader({commit, dispatch}, data) {
            commit('Loader');
        },
        unLoader({commit, dispatch}, data) {
            commit('unLoader');
        },
        async Logout({commit, dispatch}, data) {
            commit('Logout');
        },
        async fetchRequestNumbers({dispatch, commit}) {
            await axios.get('/local/api/requestnumbers').then(function (response) {
                let requestNumbers = response.data; // читать тело ответа в формате JSON
                commit('requestNumbers', requestNumbers);
            }).catch(function(err) {
                console.log(err);
            });
        },
        async fetchPrintNumbers({dispatch, commit}) {
            await axios.get('/local/api/printnumbers').then(function (response) {
                let printNumbers = response.data; // читать тело ответа в формате JSON
                commit('printNumbers', printNumbers);
            }).catch(function(err) {
                console.log(err);
            });
        },
        async fetchPatientNumbers({dispatch, commit}) {
            await axios.get('/local/api/patientnumbers').then(function (response) {
                let patientNumbers = response.data; // читать тело ответа в формате JSON
                commit('patientNumbers', patientNumbers);
            }).catch(function(err) {
                console.log(err);
            });
        },
        async fetchPatientNumbersAwards({dispatch, commit}) {
            await axios.get('/local/api/patientnumbersawards').then(function (response) {
                let patientNumbersAwards = response.data; // читать тело ответа в формате JSON
                commit('patientNumbersAwards', patientNumbersAwards);
            }).catch(function(err) {
                console.log(err);
            });
        },
        async fetchDeptNumbers({dispatch, commit}) {
            await axios.get('/local/api/deptnumbers').then(function (response) {
                let deptNumbers = response.data; // читать тело ответа в формате JSON
                commit('deptNumbers', deptNumbers);
            }).catch(function(err) {
                console.log(err);
            });
        }
    },
    getters: {
        error: s => s.error,
        isAuth: s => s.isAuth,
        user: s => s.user,
        mainpage: s => s.mainpage,
        role: s => s.role,
        loader: s => s.loader,
        config: s => s.config,
        requestNumbers: s => s.requestNumbers,
        printNumbers: s => s.printNumbers,
        patientNumbers: s => s.patientNumbers,
        patientNumbersAwards: s => s.patientNumbersAwards,
        deptNumbers: s => s.deptNumbers
    },
    modules: {
        breadcrumbs
    }
})
