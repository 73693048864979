<template>
    <h1 class="title">Долги</h1>
    <DeptMenu></DeptMenu>
    <div class="row">
        <div class="search">
            <input v-model="search" v-on:change="doSearch" v-on:keyup="doSearch" class="form-control search__input" type="text" placeholder="Поиск по ФИО">
        </div>
        <div v-if="role=='buhgalter' || role=='admin'" class="btn btn--big btn--icon js-open-modal" data-modal="add-payment" @click="isOpen=true">
            <span>Внести оплату</span>
            <svg width="30" height="30">
                <use xlink:href="@/images/sprite.svg#plus-big"></use>
            </svg>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <div class="table table-dept">
                    <div class="table-head">
                        <div class="table-cell">Пациент</div>
                        <div class="table-cell table-cell--center">Долг</div>
                        <div class="table-cell table-cell--right">Общая сумма</div>
                        <div class="table-cell"></div>
                    </div>
                    <router-link class="table-row" :to="role=='buhgalter'?'/payments-patient/'+item.id:'/depts-doctor/'+item.doctor_id" v-for="(item, id) in items">
                        <div class="table-celll table-cell--light">{{ item.name }}</div>
                        <div class="table-cell table-cell--right">{{ item.sumCurrent }} ₽</div>
                        <div class="table-cell table-cell--right">{{ item.sumTotal }} ₽</div>
                        <div class="table-cell">
                            <div class="show-more">
                                <svg width="7" height="14">
                                    <use xlink:href="@/images/sprite.svg#arrow-right"></use>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
  <Pagination v-if="page.num_page > 1" @gotopage="gotopage" :pagination="page"></Pagination>

    <div class="modal" :class="isOpen?'is-open':''" data-modal="add-payment">
        <div class="modal-overlay" @click="isOpen=false"></div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close" @click="isOpen=false">
                      <svg width="30" height="30">
                        <use xlink:href="@/images/sprite.svg#close"></use>
                      </svg>
                </div>
                <PaymentForm @close="isOpen = false" @success="isOpen = false; isSuccess = true;"></PaymentForm>
            </div>
        </div>
    </div>

    <div class="modal" :class="isSuccess?'is-open':''" data-modal="3">
        <div class="modal-overlay" @click="isSuccess = false"> </div>
        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-close">
                    <svg width="30" height="30">
                        <use xlink:href="images/sprite.svg#close"></use>
                    </svg>
                </div>
                <div class="modal__title">Оплата успешно внесена!</div>
                <div class="modal-action">
                    <div class="btn btn--primary js-modal-close" @click="isSuccess = false">Ок</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentForm from '../components/app/PaymentForm';
import axios from "axios";
import Pagination from "@/components/app/Pagination"
import DeptMenu from "@/components/app/DeptMenu"

export default {
    data: () => ({
        isOpen: false,
        isSuccess: false,
        items: {},
      pagination: {},
      search: '',
      searching: false
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      page() {
        return this.pagination;
      },
      role() {
        return this.$store.getters.role
      }
    },
    async mounted() {
        this.gotopage(1);
    },
    components: {
        PaymentForm, Pagination, DeptMenu
    },
    methods: {
      async gotopage(page, add=false) {
        let _this = this;
        let url = '/local/api/depts/list?page='+page;
        if (this.search)
          url += "&search="+this.search;
        await axios.get(url).then(function (response) {
          if (add == true)
            _this.items = _this.merge(_this.items, response.data.items);
          else
            _this.items = response.data.items;
          _this.pagination = response.data.pagination;
          _this.searching = false;
        }).catch(function(err) {
          _this.$router.push('/404');
        });
      },
      merge(arr1, arr2) {
        let item, items = [];
        for (item in arr1) {
          items.push(arr1[item]);
        }
        for (item in arr2) {
          items.push(arr2[item]);
        }
        return items;
      },
      async doSearch()
      {
        if (this.searching) {
          console.log('searching')
          return;
        }
        let val = this.search;
        if (val.length < 3 && val != '')
          return;
        /*if (this.searching == true)
          return;*/
        this.searching = true;
        await this.gotopage(1);
      }
    },
}
</script>

