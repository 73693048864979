<template>
  <template v-if="role=='doctor'">
  <div class="wrapper">
    <h1 class="title">Создать заявку на нового пациента</h1>
    <form action="#" method="post" @submit.prevent="send" ref="orderForm">
    <div class="section">
      <div class="form-group">
        <input v-model="fio" class="form-control" :class="[!fio && error.includes('fio') ? 'form-control--error' : '', fio?'is-filled':'']" type="text">
        <label class="form-label">ФИО <span>*</span></label>
      </div>
    </div>
    <div class="section">
      <div class="section__title">КЛКТ челюсти</div>
      <div class="docs">
        <template v-for="(item, index) in klkt">
          <div class="doc doc--edit">
            <svg v-if="item.save != 1 && item.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
              <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(item.progress/100)" stroke="#21D28B" ></circle>
            </svg>
            <div class="doc__name">{{ item.filename }}</div>
            <div class="doc__size">{{ item.format_size }}</div>
            <div class="doc__delete" @click="deleteFile('klkt', index)"><svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#remove"></use>
            </svg></div>
          </div>
          <div v-if="item.error" class="error_file">{{item.error}}</div>
        </template>
      </div>
      <div class="section__information">Форматы файлов: {{extention.klkt.join(', ')}}. Максимальный размер файла — {{formatBytes(maxSize.klkt)}}. </div>
      <label class="section__btn btn btn--icon btn--big">
        <input type="file" multiple  @input="addFiles('klkt')" ref="klkt"><span>Выбрать файлы</span>
        <svg width="30" height="30">
          <use xlink:href="@/images/sprite.svg#plus-big"></use>
        </svg>
      </label>
    </div>
    <div class="section">
      <div class="section__title">Сканы (.stl)</div>
      <div class="docs">
        <template v-for="(item, index) in scan">
          <div class="doc doc--edit">
            <svg v-if="item.save != 1 && item.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
              <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(item.progress/100)" stroke="#21D28B" ></circle>
            </svg>
            <div class="doc__name">{{ item.filename }}</div>
            <div class="doc__size">{{ item.format_size }}</div>
            <div class="doc__delete" @click="deleteFile('scan', index)"><svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#remove"></use>
            </svg></div>
          </div>
          <div v-if="item.error" class="error_file">{{item.error}}</div>
        </template>
      </div>
      <div class="section__information">Форматы файлов: {{extention.scan.join(', ')}}. Максимальный размер файла — {{formatBytes(maxSize.scan)}}. </div>
      <label class="section__btn btn btn--icon btn--big">
        <input type="file" multiple  @input="addFiles('scan')" ref="scan"><span>Выбрать файлы</span>
        <svg width="30" height="30">
          <use xlink:href="@/images/sprite.svg#plus-big"></use>
        </svg>
      </label>
    </div>
    <div class="section">
      <div class="section__title">Фотографии пациента</div>
      <div class="docs">
        <template v-for="(item, index) in doctorPhotos">
          <div class="doc doc--edit">
            <svg v-if="item.save != 1 && item.progress != 100" class="progressLoad" width="40" height="40" viewPort="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <circle r="18" cx="20" cy="20" fill="transparent" stroke-width="2px" stroke-dasharray="113" :stroke-dashoffset="0" stroke="#21D28B" ></circle>
              <circle r="9" cx="20" cy="20" fill="transparent" stroke-width="18px" stroke-dasharray="113" :stroke-dashoffset="113-57*(item.progress/100)" stroke="#21D28B" ></circle>
            </svg>
            <div class="doc__name">{{ item.filename }}</div>
            <div class="doc__size">{{ item.format_size }}</div>
            <div class="doc__delete" @click="deleteFile('doctorPhotos', index)"><svg width="30" height="30">
              <use xlink:href="@/images/sprite.svg#remove"></use>
            </svg></div>
          </div>
          <div v-if="item.error" class="error_file">{{item.error}}</div>
        </template>
      </div>
      <div class="section__information">Форматы файлов: {{extention.doctorPhotos.join(', ')}}. Максимальный размер файла — {{formatBytes(maxSize.doctorPhotos)}}. </div>
      <label class="section__btn btn btn--icon btn--big">
        <input type="file" multiple  @input="addFiles('doctorPhotos')" ref="doctorPhotos"><span>Выбрать фото</span>
        <svg width="30" height="30">
          <use xlink:href="@/images/sprite.svg#plus-big"></use>
        </svg>
      </label>
    </div>
    <div class="section">
      <div class="section__title">Комментарий от врача</div>
      <div class="form-group">
        <input v-model="comment" class="form-control" type="text">
      </div>
    </div>
    <div v-if="error_text">
      <div v-for="er in error_text">{{er}}</div>
    </div>
    <div class="action">
      <button type="submit" class="btn btn--big btn--primary" :d2isabled="fio ==''"><span>Создать заявку</span></button>
    </div>
    </form>
  </div>
  <div class="modal" :class="isSuccess?'is-open':''" data-modal="print-changed">
    <div class="modal-overlay"> </div>
    <div class="modal-wrapper">
      <div class="modal-content">
        <div class="modal-close" @click="close">
          <svg width="30" height="30">
            <use xlink:href="@/images/sprite.svg#close"></use>
          </svg>
        </div>
        <div class="modal__title">Отличная работа!</div>
        <div v-if="good_work" class="modal__desc">{{good_work}}</div>
        <div class="modal-action">
          <div class="btn btn--primary js-modal-close" @click="close">Ок</div>
        </div>
      </div>
    </div>
  </div>
  </template>
  <template v-else>
    <Notfound></Notfound>
  </template>
</template>

<script>
import {mask as maskDirective } from 'vue-the-mask';
import Notfound from '@/views/Notfound';
import axios from "axios";

export default {
    name: 'PatientNew',
    directives: {
        mask: function (e, b) {
            if (!b.value) {
                return;
            }

            maskDirective(e, b);
        }
    },
    data: () => ({
      error: [],
      error_text: false,
      fio: '',
      comment: '',
      klkt: {},
      scan: {},
      doctorPhotos: {},
      maxSize: {
          klkt: 99*1024*1024,
        scan: 99*1024*1024,
        doctorPhotos: 99*1024*1024
      },
      extention: {
        klkt: ['zip','rar', 'klkt'],
        scan: ['zip','rar', '7z'],
        doctorPhotos: ['jpg','png', 'jpeg', 'tiff'],
      },
      isSuccess: false,
      sending: false,
      good_work: '',
      id: 0
    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {
      role() {
        return this.$store.getters.role;
      }
    },
    mounted() {
        //this.$store.dispatch('Loader');
    },
    components: {Notfound},
    methods: {
        send: function(e) {
          this.good_work = '';
          if (this.sending === true)
            return;
            let form = e.target,
                formData = new FormData(),
                _this = this;

            this.error = [];

            formData.append('fio', this.fio);
            formData.append('comment', this.comment);
          let i;
          for( i in this.klkt){
            let file = this.klkt[i];
            if (file.error) {
              this.error.push('klkt');
            }
            formData.append('klkt[' + i + ']', file.id);
          }
          for( i in this.scan){
            let file = this.scan[i];
            if (file.error) {
              this.error.push('scan');
            }
            formData.append('scan[' + i + ']', file.id);
          }
          for( i in this.doctorPhotos){
            let file = this.doctorPhotos[i];
            if (file.error) {
              this.error.push('doctorPhotos');
            }
            formData.append('doctorPhotos[' + i + ']', file.id);
          }

            if (!this.fio)
              this.error.push('fio');

            if (this.error.length == 0) {
              this.sending = true;
              axios.post( '/local/api/patient/request',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
              ).then(function(response){
                if (response.data.id) {
                  //_this.$router.push('/order/new/'+response.data.id)
                  _this.success();
                  _this.id = response.data.id;
                  _this.good_work = response.data.good_work;
                  _this.sending = false;
                }
                else {
                  _this.error_text = response.data.error;
                  _this.sending = false;
                }
              })
                  .catch(function(){
                    _this.sending = false;
                  });
                //this.success();
            }
        },
        close: function() {
          this.isSuccess = false;
          this.$router.push('/')
        },
        success: function() {
            this.error = [];
            this.error_text = false;
            this.fio = '';
            this.comment = '';
            this.klkt = {};
            this.scan = {};
            this.doctorPhotos = {};
            this.isSuccess = true;

        },
      addFiles: function(e) {
        let time = Date.now();
        for (let i = 0; i < this.$refs[e].files.length; i++) {
          let file = this.$refs[e].files[i];
          file.format_size = this.formatBytes(file.size);
          let error = this.checkSize(file, e);
          if (error)
            file.error = error;
          else {
            error = this.checkExtention(file, e);
            if (error)
              file.error = error;
          }
          file.format_size = this.formatBytes(file.size);
          file.filename = file.name;
          file.save = 0;
          file.progress = 0;
          file.rand = Math.floor(Math.random() * 999999);
          /* let reader  = new FileReader();
           reader.addEventListener("load", function (e, filename, file, res) {
             this.$data[e][this.$data[e].length-1].link = res.target.result
             this.upd();
           }.bind(this, e, file.name, file), false);
           reader.readAsDataURL( file );*/

          this.$data[e][time+file.size] = {filename: file.name, save: 0, progress: 0, link: '', rand: file.rand};
          this.loadFile(file, e, file.rand)
        }
        this.$refs[e].value = '';
      },
      loadFile(file, e, rand_id) {
        let _this = this;
        let formData = new FormData();
        formData.append('file', file);
        formData.append('rand', file.rand);
        axios.post( '/local/api/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: function(progressEvent) {
                for (let i in _this.$data[e]) {
                  if (_this.$data[e][i].rand == rand_id) {
                    _this.$data[e][i].progress = parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100));
                    break;
                  }
                }
              }
            }
        ).then(function(response){
          if (response.data) {
            for (let i in _this.$data[e]) {
              if (_this.$data[e][i].rand == rand_id) {
                _this.$data[e][i].progress = 100;
                //_this.$data[e][i].save = 1;
                _this.$data[e][i].id = response.data.file.id;
                _this.$data[e][i].link = response.data.file.link;
                _this.$data[e][i].format_size = response.data.file.format_size;
                break;
              }
            }
          }
          else {

          }
        })
            .catch(function(){

            });
      },
      checkSize: function(file, code) {
        let error = '';
        if (this.maxSize[code] < file.size)
          error = 'Превышен размер файла';
        return error;
      },
      checkExtention: function(file, code) {
        let error = '';
        if (!this.extention[code].includes(file.name.split('.')[file.name.split('.').length-1].toLowerCase()))
          error = 'Недопустимое расширение файла';
        return error;
      },
      formatBytes: function(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['б', 'кб', 'Мб', 'Гб', 'Тб']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
      },
      deleteFile: function(code, key) {
        delete this.$data[code][key];
      }
    },
    emits: ['close', 'success'],
}
</script>
