<template>
  <div v-if="pagination.num_page > pagination.page" @click="gotopage(pagination.page+1, true)" class="btn btn--big btn--icon"><span>Загрузить</span>
    <svg width="30" height="30">
      <use xlink:href="@/images/sprite.svg#refresh"></use>
    </svg>
  </div>
  <div class="pagination" v-if="pagination && pagination.num_page>1">
    <div class="pagination-item" :class="pagination.page==1?'is-active':''" @click="gotopage(1)">1</div>
    <div v-if="pagination.page>3" class="pagination-dots">...</div>
    <template v-if="pagination.num_page > 2">
      <template v-for="i in pagination.pages">
        <div class="pagination-item" :class="pagination.page==i?'is-active':''" @click="gotopage(i)">{{i}}</div>
      </template>

    </template>
    <div v-if="pagination.page+2<pagination.num_page" class="pagination-dots">...</div>
    <div class="pagination-item" :class="pagination.page==pagination.num_page?'is-active':''" @click="gotopage(pagination.num_page)">{{ pagination.num_page }}</div>
  </div>
</template>

<script>
import {mask as maskDirective } from 'vue-the-mask';

export default {
  props: {
    pagination: {}
  },
    data: () => ({

    }),
    beforeRouteLeave(to, from) {
        //if (to.name != 'login')
        //  this.$store.dispatch('unLoader');
    },
    created() {

    },
    computed: {

    },
    mounted() {

    },
    components: {},
    methods: {
        gotopage(page, add=false) {
          if (page != this.$props.pagination.page)
            this.$emit('gotopage', page, add);
        }
    },
    emits: ['gotopage'],
}
</script>
